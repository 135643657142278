import styled, { css } from "styled-components";
import { colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import { type ProductCardModifier } from "_types/ProductCard";

const modifier = {
  variant: {
    normal: css`
      .product__image {
        background-color: ${colors.lightGreen};
        height: 210px;
      }
      .product__data {
        margin-top: 16px;
      }
    `,
    burst: css`
      .product__image {
        height: 290px;
        ::before {
          content: "";
          position: absolute;
          background-color: ${colors.primary};
          width: 100%;
          height: 220px;
          border-radius: 16px;
          bottom: 0;
        }
      }
      .product__data {
        position: sticky;
        background-color: ${colors.secondary};
        z-index: 1;
        margin-top: -27px;
        padding: 27px 14px 16px 14px;
        border-radius: 32px 32px 16px 16px;
      }
    `
  }
};

export const ContainerProductCard = styled.div<ProductCardModifier>`
  ${({ variant, productSelected }) => css`
    width: 100%;
    height: fit-content;
    ${modifier.variant[variant]}
    .product__image {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 16px;
      .image__switch {
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        transition: all 0.3s;
        left: ${`-${productSelected * 100}%`};
        .image__item {
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          margin: auto;
        }
      }
    }
    .product__data {
      .product__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header__name {
          color: ${colors.white};
          font-size: ${pxToRem(16)};
          line-height: ${pxToRem(18.75)};
          letter-spacing: 0.3px;
          font-weight: 700;
          min-height: 45px;
          display: flex;
          align-items: center;
        }
      }
      .header__description {
        color: ${colors.white};
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(17)};
        letter-spacing: 0.3px;
        font-weight: 300;
        margin-top: 18px;
        min-height: 40px;
        display: flex;
        align-items: center;
      }
      .product__color-options {
        display: flex;
        column-gap: 8px;
        align-items: center;
        margin-top: 16px;
        min-height: 20px;
        .option__item {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          cursor: pointer;
          position: relative;
          transition: all 0.25s ease-out;
          border-width: 0;
        }
        .option__item--selected {
          border-style: solid;
          border-color: ${colors.primary};
          border-width: 2px;
          cursor: auto;
        }
      }
      .product__button {
        width: 100%;
        margin-top: 16px;
      }
    }
  `}
`;
