import styled from "styled-components";
import { breakPoints } from "styled/theme";

export const ContainerShirts = styled.aside`
  position: inherit;
  z-index: 1;
  .shirts__roll {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    z-index: 1;
    overflow-x: hidden;
    .roll__item {
      object-fit: contain;
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
      transition: all 1s;
    }
    .roll__item--front {
      z-index: 1;
    }
    .roll__item--back {
      cursor: pointer;
      scale: 0.8;
    }
  }
  @media screen and (max-width: ${breakPoints.small}) {
    width: calc(100% + 32px);
    margin-left: -16px;
    .shirts__roll {
      margin: 62px 0 64px;
      .roll__item {
        width: 90%;
      }
      .roll__item--front {
        margin-left: 16px;
      }
      .roll__item--back {
        margin-left: 60%;
      }
    }
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    grid-column: 6 / span 1;
    grid-row: 1 / 1;
    .shirts__roll {
      position: absolute;
      height: 100vh;
      .roll__item {
        max-height: 70vh;
        max-width: 598px;
        margin: auto;
      }
      .roll__item--front {
        margin-left: 0;
      }
      .roll__item--back {
        margin-left: 30vh;
      }
    }
  }

  @media screen and (max-width: ${breakPoints.smallMobile}) {
    width: calc(100% + 32px);
    .shirts__roll {
      margin: 62px 0 64px;

      .roll__item {
        width: 65%;
      }
      .roll__item--front {
        margin-left: calc(50% - 40%);
      }
      .roll__item--back {
        margin-left: 32%;
      }
    }
  }
`;
