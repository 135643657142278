import { apiToken, baseAPI } from "services/api";
import { WISHLISTS } from "services/api/endpoints";
import type {
  RequestDeleteWishlistData,
  postWishlistProps,
  RequestWishlistData
} from "_types/Wishlist";

export async function getWishlist({
  email
}: {
  email: string;
}): Promise<RequestWishlistData> {
  const response = await baseAPI.get<RequestWishlistData>(
    `${WISHLISTS}?filters[user][$eq]=${email}&populate[product][populate]=images`,
    apiToken
  );
  return response.data;
}

export async function deleteWishlist(id: number): Promise<RequestWishlistData> {
  const response = await baseAPI.delete<RequestWishlistData>(
    `${WISHLISTS}/${id}`,
    apiToken
  );
  return response.data;
}

export async function postWishlist({
  payload
}: postWishlistProps): Promise<RequestDeleteWishlistData> {
  const response = await baseAPI.post<RequestDeleteWishlistData>(
    WISHLISTS,
    { data: payload },
    apiToken
  );
  return response.data;
}
