import { useState } from "react";
import { type tagsVariant } from "_types/Tags";
import { type useTop10Data } from "_types/Top10";
import { useSelector } from "react-redux";
import { selectProducts } from "config/store/slices/productSlice";

export function useTop(): useTop10Data {
  const [currenTab, setCurrenTab] = useState<tagsVariant>("new");
  const { showcase } = useSelector(selectProducts);

  function onChangeTab(value: tagsVariant): void {
    setCurrenTab(value);
  }

  return {
    onChangeTab,
    currenTab,
    products:
      currenTab === "new"
        ? showcase.filter(item => !item.attributes.featured).slice(0, 3)
        : showcase.filter(item => item.attributes.featured).slice(0, 3)
  };
}
