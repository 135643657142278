import styled from "styled-components";
import { breakPoints, colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";

export const ContainerShirts = styled.aside`
  position: inherit;
  z-index: 1;
  .background_img {
    position: absolute;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    img {
      height: 100%;
    }
  }
  .thumbnail_img {
    position: absolute;
    right: 0px;
    top: calc(50% - 258px);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 516px;
    }
  }
  .shirts__roll {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    z-index: 1;
    right: 10px;
    overflow-x: hidden;
    .skeleton_card {
      background-color: ${colors.primary};
      width: 80px;
      height: 80px;
      margin: -30px 20px 0px 0px;
    }
    .imgSelected {
      object-fit: contain;
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
      transition: all 0.7s;
      z-index: 1;
      scale: 1;
      opacity: 1;
      width: 74vh;
      margin: 0px !important;
    }
    .imgDisbled {
      object-fit: contain;
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
      transition: all 0.7s;
      cursor: pointer;
      scale: 0.8;
      opacity: 0;
      margin: 0px !important;
    }
    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: ${colors.primary};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
    .box_presentation {
      object-fit: contain;
      grid-column: 1 / span 3;
      grid-row: 1 / span 1;
      transition: all 0.7s;
      z-index: 1;
      scale: 1;
      opacity: 1;
      margin: 15vh 40% auto 0vh;
      width: 65vh;
      height: 57vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .skeketon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .imgLoader {
          position: absolute;
          width: 53vh;
          margin: 0px 0px 0px 2vh;
        }
      }
    }

    & .section_bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 550px;
      padding-top: 20px;
      margin-bottom: -10px;
      display: none;
      .home__scroll {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 7px 7px 7px 0;
        .scroll__text {
          font-size: ${pxToRem(14)};
          text-transform: uppercase;
          line-height: ${pxToRem(18.75)};
          color: ${colors.primary};
          letter-spacing: -0.02em;
          font-weight: 500;
        }
      }
    }
  }
  @media screen and (max-width: ${breakPoints.tablet}) {
    .shirts__roll {
      & .section_bottom {
        display: flex !important;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.small}) {
    width: calc(100% + 32px);
    margin-left: -16px;
    .shirts__roll {
      margin: 62px 0 64px;
      img {
        width: 90%;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.average}) {
    width: calc(100% + 32px);
    margin-left: -16px;
    .shirts__roll {
      img {
        width: 60vh;
      }
      .box_presentation {
        object-fit: contain;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
        transition: all 0.7s;
        z-index: 1;
        scale: 1;
        opacity: 1;
        margin: 20vh 40% auto auto;
        width: 60vh;
        height: 50vh;
        .skeketon {
          width: 100%;
          height: 100%;
          .imgLoader {
            position: absolute;
            width: 53vh;
            margin: 0px 0px 0px 2vh;
          }
        }
      }
      .imgSelected {
        margin-top: 20vh;
        margin-right: 10vh;
      }
      .imgDisbled {
        margin-top: 20vh;
        margin-right: 10vh;
      }
    }
    .shirts_select_item {
      margin-right: -50px !important;
      justify-content: end !important;
      margin-bottom: 40px !important;
    }
  }
  @media screen and (min-width: ${breakPoints.mobile}) {
    grid-column: 6 / span 1;
    grid-row: 1 / 1;
    .shirts__roll {
      position: absolute;
      height: 100vh;
      img {
        margin: 15vh 40% auto auto;
        width: 71vh;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .shirts__roll {
      .imgSelected {
        margin-top: 0vh !important;
      }
      .imgDisbled {
        margin-top: 0vh !important;
      }
      img {
        width: 55vh !important;
      }
    }

    .box_presentation {
      object-fit: contain;
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
      transition: all 0.7s;
      z-index: 1;
      scale: 1;
      margin: 25vh 10% auto auto !important;
      width: 45vh !important;
      height: 40vh !important;
      .skeketon {
        width: 100%;
        height: 100%;
        .imgLoader {
          position: absolute;
          width: 40vh !important;
          margin: 0px 0px 0px 2vh;
        }
      }
    }
    .thumbnail_img {
      position: absolute;
      right: 0px;
      top: calc(50% - 128px);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 306px;
      }
    }
  }
  .shirts_select_item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 50%;
    & .section_badge {
      display: flex;
      height: 30px;
      justify-content: center;
      align-items: center;
      margin-left: 40px;

      & button {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 2px solid ${colors.primary};
        background-color: transparent;
        cursor: pointer;
      }
      & .buttonSelected {
        width: 20px;
        height: 20px;
        .box_presentation {
          object-fit: contain;
          grid-column: 1 / span 2;
          grid-row: 1 / span 1;
          transition: all 0.7s;
          z-index: 1;
          scale: 1;
          opacity: 1;
          margin: 15vh 40% auto auto;
          width: 60vh;
          height: 50vh;
        }
        border: none;
        background-color: ${colors.primary};
      }
      & .box_badge {
        width: 190px;
        height: 10px;
        margin: 3px 10px 0px 10px;
        border-top-style: dotted;
        border-top-width: 7px;
        border-color: ${colors.primary};
      }
    }
    .section_card_item {
      display: flex;
      flex-direction: row;
      margin-right: 40px;
      .active_card_item {
        background-color: ${colors.white};
        width: 200px;
        height: 75px;
        border-radius: 20px;
        display: flex;
        padding: 10px;
        margin-top: 40px;
        transition: all 0.5s;
        margin-right: 40px;
        .img_card_item {
          img {
            margin-top: -50px;
            height: 102px;
            transition: all 0.3s;
            width: 80px !important;
          }
        }
        .info_card_item {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          p {
            color: ${colors.dark};
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
      .disabled_card_item {
        background-color: transparent;
        width: 200px;
        height: 75px;
        border-radius: 20px;
        display: flex;
        padding: 10px;
        margin-top: 40px;
        margin-right: 40px;
        .img_card_item {
          img {
            margin-top: -20px;
            height: 102px;
            width: 110px !important;
            margin-left: -20px;
            cursor: pointer;
          }
        }

        .info_card_item {
          display: none;
          transition: all 0.5s;
        }
      }
    }
  }
  @media screen and (max-width: ${breakPoints.mobile}) {
    .background_img {
      position: absolute;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      top: 0px !important;
      img {
        height: 100%;
      }
    }
    .skeleton_card {
      background-color: ${colors.primary};
      width: 60px !important;
      height: 60px !important;
      margin: -30px 0px 10px 0px !important;
    }
    .skeleton_card_favorite {
      display: none;
    }
    .box_badge {
      width: 170px !important;
      height: 10px;
      margin: 3px 10px 0px 10px;
      border-top-style: dotted;
      border-top-width: 7px;
      border-color: ${colors.primary};
    }
    .thumbnail_img {
      position: absolute;
      right: 0px;
      top: 45vh !important;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 180px !important;
      }
    }
    .shirts__roll {
      height: 70vh !important;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      flex-direction: column;
      width: 100% !important;
      right: 0px !important;
      margin: 0px !important;
      .imgDisbled {
        height: 40vh !important;
        margin-top: 0px !important;
      }
      .imgSelected {
        height: 40vh !important;
        margin-top: 0px !important;
      }
      .box_presentation {
        position: absolute !important;
        object-fit: contain;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
        transition: all 0.7s;
        z-index: 1;
        scale: 1;
        margin: -30vh 10% auto auto !important;
        width: 45vh !important;
        height: 40vh !important;
        position: absolute !important;
        .skeketon {
          width: 100%;
          height: 100%;
        }
      }
    }
    .shirts_select_item {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      width: 100%;
      margin-top: 50vh;
      margin-right: -0px !important;
      & .section_badge {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
        margin-left: -120px !important;
      }
      .section_card_item {
        display: flex;
        flex-direction: row;
        margin-right: 0px !important;
        .active_card_item {
          background-color: ${colors.white};
          border-radius: 12px !important;
          display: flex;
          padding: 7px !important;
          margin-top: 40px;
          transition: all 0.5s;
          margin-left: 10px !important;
          margin-right: 10px !important;

          .info_card_item {
            p {
              color: ${colors.dark};
              font-weight: 500;
            }
          }
        }
        .disabled_card_item {
          background-color: transparent;
          border-radius: 20px;
          display: flex;
          padding: 10px;
          margin-top: 40px;
          margin-left: 10px !important;
          margin-right: 10px !important;
          .info_card_item {
            font-size: 12px !important;
            display: none;
            transition: all 0.5s;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${breakPoints.smallMobile}) {
    .thumbnail_img {
      top: 40vh !important;
    }
    .shirts__roll {
      justify-content: end !important;
      .shirts_select_item {
        margin-top: 45vh !important;
        margin-bottom: 0px !important;
      }
      .imgDisbled {
        width: 40vh !important;
      }
      .imgSelected {
        width: 50vh !important;
      }
      .box_presentation {
        position: absolute !important;
        object-fit: contain;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
        transition: all 0.7s;
        z-index: 1;
        scale: 1;
        margin: 0vh 0% 30vh auto !important;
        width: 45vh !important;
        height: 40vh !important;
        position: absolute !important;
        .skeketon {
          width: 100%;
          height: 100%;
          .imgLoader {
            position: absolute;
            width: 35vh !important;
            margin: 0px 0px 0px 2vh;
          }
        }
      }

      .section_badge {
        margin-left: -10px !important;
        button {
          width: 20px !important;
          height: 20px !important;
        }

        & .box_badge {
          width: 75px !important;
          height: 10px;
          margin: 3px 10px 0px 10px;
          border-top-style: dotted;
          border-top-width: 7px;
          border-color: ${colors.primary};
        }
      }

      .section_card_item {
        gap: 0px !important;

        .active_card_item {
          flex-direction: column !important;
          height: 90px !important;
          align-items: center !important;
          justify-content: center !important;
          width: 120px !important;
          margin-top: 20px !important;
          transition: all 0.3s;
          margin-right: 0px !important;
          margin-left: 0px !important;

          .img_card_item {
            img {
              height: auto !important;
              width: 70px !important;
              margin-bottom: 5px !important;
              margin-top: -10px !important;
            }
          }
          .info_card_item {
            p {
              opacity: 1 !important;
              font-size: 14px;
            }
          }
        }
        .disabled_card_item {
          width: 120px !important;
          height: 90px !important;
          justify-content: center !important;
          align-items: center !important;
          margin-top: 20px !important;
          flex-direction: column;
          display: flex;
          margin-right: 0px !important;
          margin-left: 0px !important;

          .img_card_item {
            img {
              height: auto !important;
              width: 70px !important;
              margin-top: 20px !important;
              margin-left: 0px !important;
            }
          }
          .info_card_item {
            color: ${colors.dark} !important;
            font-weight: 500 !important;
            display: block !important;
            opacity: 0 !important;
            height: 40px !important;
            font-size: 16px !important;
          }
        }
      }
      .section_bottom {
        width: 100% !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;

        .home__scroll {
          svg {
            width: 15px !important;
            margin-top: -3px !important;
          }

          .scroll__text {
            font-size: ${pxToRem(11)};
          }
        }
      }
    }
  }
`;
