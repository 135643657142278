import { colors } from "styled/theme";

export function Components(): JSX.Element {
  return (
    <div
      style={{
        backgroundColor: colors.dark,
        // backgroundColor: "#000000",
        padding: 40,
        minHeight: "100vh",
        minWidth: "100vw",
        display: "grid",
        columnGap: 50,
        gridTemplateColumns: "repeat(5, 1fr)"
      }}
    >
      {/* {mockProducts.map((item, index) => (
        <ProductCard
          key={index}
          data={item}
          variant={index % 2 === 0 ? "burst" : "normal"}
        />
      ))} */}
    </div>
  );
}
