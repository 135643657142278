import { type DefaultIconProps } from "_types/Icons";

export function ScrollIcon({
  className,
  fill = "#C6C6C6"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-3.03787e-07 7.925C-2.85574e-07 7.50834 0.179333 7.21267 0.538 7.038C0.896 6.86267 1.24167 6.89167 1.575 7.125L7 11L12.425 7.125C12.7583 6.89167 13.1043 6.86267 13.463 7.038C13.821 7.21267 14 7.50834 14 7.925C14 8.10834 13.9543 8.275 13.863 8.425C13.771 8.575 13.6583 8.7 13.525 8.8L7.575 13.025C7.49167 13.0917 7.4 13.1417 7.3 13.175C7.2 13.2083 7.1 13.225 7 13.225C6.9 13.225 6.80433 13.2083 6.713 13.175C6.621 13.1417 6.525 13.0917 6.425 13.025L0.475 8.8C0.341667 8.7 0.228999 8.575 0.136999 8.425C0.0456654 8.275 -3.11801e-07 8.10834 -3.03787e-07 7.925ZM-4.37043e-08 1.975C-2.54912e-08 1.55834 0.179334 1.26267 0.538 1.088C0.896 0.912669 1.24167 0.94167 1.575 1.175L7 5.05L12.425 1.175C12.7583 0.94167 13.1043 0.91267 13.463 1.088C13.821 1.26267 14 1.55834 14 1.975C14 2.15834 13.9543 2.325 13.863 2.475C13.771 2.625 13.6583 2.75 13.525 2.85L7.575 7.075C7.49167 7.14167 7.4 7.19167 7.3 7.225C7.2 7.25834 7.1 7.275 7 7.275C6.9 7.275 6.8 7.25834 6.7 7.225C6.6 7.19167 6.50833 7.14167 6.425 7.075L0.475 2.85C0.341667 2.75 0.228999 2.625 0.136999 2.475C0.0456657 2.325 -5.1718e-08 2.15834 -4.37043e-08 1.975Z"
        fill="#3ED206"
      />
    </svg>
  );
}
