import styled from "styled-components";
import { breakPoints, colors } from "styled/theme";

export const ContainerDesktop = styled.aside`
  height: 100vh;
  width: 98px;
  z-index: 2;
  background-color: ${colors.secondary};
  position: fixed;
  & div {
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: start;
    & img {
      margin-top: 60px;
    }
  }
  & nav {
    height: 45%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    & button {
      background-color: transparent;
      transition: all 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      cursor: pointer;
      transform: rotate(-90deg);
      flex-direction: column;
      margin-bottom: 10px;
      & p {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: ${colors.tertiary};
      }
      & span {
        width: 0%;
        border-radius: 6px;
        transition: all 0.3s;
        height: 6px;
        display: block;
      }
    }
    & .buttonSelected {
      & p {
        color: ${colors.primary};
        font-weight: 500;
      }
      & span {
        background-color: ${colors.primary};
        width: 100%;
        display: block;
      }
    }
  }

  & footer {
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & button {
      margin-bottom: 15px;
      margin-top: 15px;
      background-color: transparent;
      transition: all 0.5s;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: ${colors.homeBackground};
      }
      & img {
        width: 18px;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.mobile}) {
    display: none;
  }
`;
export const ContainerMobile = styled.aside`
  width: 100%;
  height: 70px;
  position: fixed;
  display: none;
  z-index: 2;
  .box_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 30px;
    padding-right: 20px;
    transition: all 0.2s;
    width: 100%;
    position: absolute;
    z-index: 2;
    background-color: ${colors.secondary};
  }
  .box_header_active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 30px;
    padding-right: 20px;
    transition: all 0.2s;
    width: 100%;
    position: absolute;
    z-index: 2;
    background-color: ${colors.primary};
  }
  .button_header_open_active {
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
  }
  .button_header_open_disable {
    display: none;
  }

  .button_header_close_active {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
  }
  .button_header_close_disable {
    display: none;
  }

  .box_header_info {
    background-color: ${colors.primary};
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    right: 0px;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .box_img_review {
      height: 85vh;
      margin-top: 10vh;
      transition-delay: 0.3s;
      transition-duration: 0.3s;
      margin-left: -0%;
      display: flex;
      align-items: center;
      .img_review {
        position: absolute;
        height: 85vh;
      }
      .img_review_shadow {
        position: absolute;
        height: 73vh;
      }
    }
  }
  .box_header_info_close {
    background-color: ${colors.primary};
    width: 0%;
    height: 100vh;
    position: absolute;
    top: 0px;
    right: 0px;
    transition: all 0.5s;
    overflow: hidden;
    .box_img_review_close {
      height: 85vh;
      margin-top: 10vh;
      margin-left: -100%;
      opacity: 0;
    }
  }
  .box_links_close {
    transition-delay: 0.5s;
    transition-duration: 0.5s;
    opacity: -2;
    overflow: hidden;
  }
  .box_links {
    transition-delay: 0.5s;
    transition-duration: 0.5s;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    position: absolute;
    height: 90vh;
    top: 10vh;
    right: 0px;
    width: 50vw;
    padding-right: 30px;
    div {
      display: flex;
      gap: 13px;
      span {
        display: flex;
        flex-direction: column;
        align-items: end;
        h1 {
          font-size: 18px;
          line-height: 23px;
          font-weight: 700;
          color: ${colors.secondary};
          text-align: end;
        }
      }
      img {
        width: 46px;
        height: 46px;
        background-color: white;
        border-radius: 100%;
      }
    }
    ul {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 30px;
      justify-content: center;
      li {
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 17px;
        align-items: center;
        .text_link {
          font-size: 18px;
          font-weight: 300;
          color: ${colors.secondary};
        }
        .text_link_select {
          font-size: 18px;
          font-weight: 700;
          color: ${colors.secondary};
        }
        .button_link {
          background: rgba(0, 0, 0, 0.15);
          width: 43px;
          height: 43px;
          border-radius: 12px;
          cursor: pointer;
        }
        .button_link_select {
          background: ${colors.secondary};
          width: 43px;
          height: 43px;
          border-radius: 12px;
          cursor: pointer;
        }
      }
    }
    footer {
      display: flex;
      align-items: center;
      flex: 1.5;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #232323;
      }
      button {
        padding: 10px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        border-radius: 10px;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.mobile}) {
    display: block;
  }
  @media screen and (max-width: ${breakPoints.smallMobile}) {
    .box_header_info {
      .box_img_review {
        height: 90vh !important;
        .img_review {
          height: 80vh !important;
          margin-bottom: 5vh !important;
        }
        .img_review_shadow {
          height: 65vh !important;
        }
      }
    }
    .box_links {
      height: 90vh !important;
      top: 13vh !important;
      width: 65vw !important;
      padding-right: 15px !important;
      div {
        gap: 10px !important;
        padding-bottom: 20px !important;
        align-items: center !important;
        h1 {
          font-size: 15px !important;
          line-height: 18px !important;
        }
      }
    }
  }
`;
