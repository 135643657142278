import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "config/store";
import type {
  mergeProductsWithFavoritesProps,
  mergeProductsWithFavoritesData,
  initialsliceProduct,
  onPopulateProductsProps,
  onAddFavoriteProductProps,
  addFavoriteProductData,
  addFavoriteProductProps,
  onRemoveFavoriteProductProps,
  removeFavoriteProductProps,
  removeFavoriteProductData
} from "_types/store/Products";

const initialState: initialsliceProduct = {
  showcase: [],
  wishlist: []
};

function mergeProductsWithFavorites({
  products,
  wishlist
}: mergeProductsWithFavoritesProps): mergeProductsWithFavoritesData {
  const productMerged = products.map(element => {
    const itemFavorite = wishlist.filter(
      item => item.attributes.product.data.id === element?.id
    );
    if (itemFavorite.length > 0) {
      return {
        ...element,
        favorits: itemFavorite.map(item => ({
          variant: item.attributes.variant ?? ""
        }))
      };
    }
    return {
      ...element,
      favorits: []
    };
  });

  const getOnlyFavorites = wishlist.map(element => {
    const getProduct = products.filter(
      item => element.attributes.product.data.id === item.id
    )[0];
    return {
      ...getProduct,
      idFavorite: element.id,
      favorits: [{ variant: element.attributes.variant }]
    };
  });

  return {
    showcase: productMerged,
    wishlist: getOnlyFavorites
  };
}

function addFavoriteProduct({
  showcase,
  wishlist,
  payload
}: addFavoriteProductProps): addFavoriteProductData {
  const { idProduct, idFavorite, variant } = payload;
  const updateDataShowcaseProducts = showcase.map(element => {
    if (element.id === idProduct) {
      return {
        ...element,
        favorits: [...(element?.favorits ?? []), { variant }]
      };
    }
    return element;
  });

  const updateDataWishlist = [
    ...wishlist,
    {
      ...showcase.filter(item => item.id === idProduct)[0],
      idFavorite,
      variant,
      favorits: [{ variant }]
    }
  ];
  return {
    showcase: updateDataShowcaseProducts,
    wishlist: updateDataWishlist
  };
}

function removeFavoriteProduct({
  showcase,
  wishlist,
  payload
}: removeFavoriteProductProps): removeFavoriteProductData {
  const { idProduct, idFavorite, variant } = payload;

  const updateDataShowcaseProducts = showcase.map(element => {
    if (element.id === idProduct) {
      return {
        ...element,
        favorits: element?.favorits?.filter(item => item.variant !== variant)
      };
    }
    return element;
  });
  const updateDataWishlist = wishlist.filter(
    item => item.idFavorite !== idFavorite
  );

  return {
    showcase: updateDataShowcaseProducts,
    wishlist: updateDataWishlist
  };
}

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    onPopulateProducts(_, { payload }: PayloadAction<onPopulateProductsProps>) {
      return mergeProductsWithFavorites(payload);
    },
    onAddFavoriteProduct(
      state,
      { payload }: PayloadAction<onAddFavoriteProductProps>
    ) {
      return addFavoriteProduct({
        showcase: state.showcase,
        wishlist: state.wishlist,
        payload
      });
    },
    onRemoveFavoriteProduct(
      state,
      { payload }: PayloadAction<onRemoveFavoriteProductProps>
    ) {
      return removeFavoriteProduct({
        showcase: state.showcase,
        wishlist: state.wishlist,
        payload
      });
    }
  }
});

export const {
  onPopulateProducts,
  onAddFavoriteProduct,
  onRemoveFavoriteProduct
} = productSlice.actions;

export const products = productSlice.reducer;

export const selectProducts = (state: RootState): initialsliceProduct =>
  state.products;
