import React from "react";
import {
  onAddFavoriteProduct,
  onRemoveFavoriteProduct
} from "config/store/slices/productSlice";
import { selectUser } from "config/store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteWishlist, postWishlist } from "services/hooks/useWishilist";
import type {
  getDataImagesVariantsData,
  onChangeVariantProps,
  useProductCardData
} from "_types/ProductCard";

export function useProductCard({ product }: any): useProductCardData {
  const isFavorite = product?.favorits.length > 0;
  const dispatch = useDispatch();
  const { email } = useSelector(selectUser);
  const [isAwaitRequest, setIsAwaitRequest] = React.useState(false);
  const initialDataVariant = !isFavorite
    ? {
        position: 0,
        nameVariant:
          product.attributes.images.data[0]?.attributes?.alternativeText ??
          "item unico"
      }
    : {
        position: product.attributes.images.data.findIndex(
          (item: any) =>
            item?.attributes?.alternativeText === product.favorits[0].variant
        ),
        nameVariant: product.favorits[0].variant
      };
  const [variantSelected, setVariantSelected] = React.useState<{
    position: number;
    nameVariant: string;
  }>(initialDataVariant);
  const urlBack = process.env.REACT_APP_BASE_URL_BACK ?? "";

  function onChangeVariant({
    position,
    nameVariant
  }: onChangeVariantProps): void {
    const isDifferentFromSelected = position !== variantSelected.position;
    if (isDifferentFromSelected) {
      setVariantSelected({
        position,
        nameVariant
      });
    }
  }

  function getDataImagesVariants(): getDataImagesVariantsData[] {
    const data = product.attributes.images.data.map(
      (item: any, index: number) => ({
        positition: index,
        nameVariant: item?.attributes?.alternativeText ?? "item unico",
        photo: `${urlBack}${String(item?.attributes?.url)}` ?? "",
        hexadecimal: item?.attributes?.caption
      })
    );

    if (product.isFavorite === true) {
      const getImageVariant = data.filter(
        (item: any) => item?.nameVariant === product.variant
      );
      return getImageVariant;
    }
    return data;
  }

  function getPayloadAddFavorite(): {
    id: number;
    variant: string;
  } {
    const dataVariantsFormated = getDataImagesVariants();
    const existVariant = dataVariantsFormated.length > 1;
    if (existVariant && variantSelected.nameVariant === "initialState") {
      return {
        id: product.id,
        variant: dataVariantsFormated[0].nameVariant
      };
    }

    if (!existVariant) {
      return {
        id: product.id,
        variant: "item unico"
      };
    }

    return {
      id: product.id,
      variant: variantSelected.nameVariant
    };
  }

  async function onAddFavorite({
    id,
    variant
  }: {
    id: number;
    variant: string;
  }): Promise<void> {
    try {
      setIsAwaitRequest(true);
      const payload = {
        product: id,
        user: email ?? "",
        variant
      };
      const response = await postWishlist({ payload });
      dispatch(
        onAddFavoriteProduct({
          idProduct: id,
          idFavorite: response.data.id,
          variant
        })
      );
    } catch (error) {
    } finally {
      setIsAwaitRequest(false);
    }
  }

  async function onRemoveFavorite({
    idProduct,
    idFavorite,
    variant
  }: {
    idProduct: number;
    idFavorite: number;
    variant: string;
  }): Promise<void> {
    try {
      setIsAwaitRequest(true);
      await deleteWishlist(idFavorite);
      dispatch(
        onRemoveFavoriteProduct({
          idProduct,
          idFavorite,
          variant
        })
      );
    } catch (error) {
    } finally {
      setIsAwaitRequest(false);
    }
  }

  return {
    onChangeVariant,
    onAddFavorite,
    onRemoveFavorite,
    variantSelected,
    imagesVariants: getDataImagesVariants(),
    payloadAddFavorite: getPayloadAddFavorite(),
    isAwaitRequest
  };
}
