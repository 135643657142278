import * as icons from "assets/icons";
import { type RenderIconProps, type ButtonCardProps } from "_types/ButtonCard";
import { ContainerButtonCard } from "./style";

function RenderIcon({ iconName }: RenderIconProps): JSX.Element {
  const Icon = icons[iconName];
  return <Icon />;
}

export function Button({
  onClick,
  label,
  variant = "primary",
  iconName,
  className,
  disabled
}: ButtonCardProps): JSX.Element {
  const shouldRenderIcon = iconName !== undefined;
  return (
    <ContainerButtonCard
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="button__text">{label}</span>
      {shouldRenderIcon && <RenderIcon iconName={iconName} />}
    </ContainerButtonCard>
  );
}
