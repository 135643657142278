import { type DefaultIconProps } from "_types/Icons";

export function UserStarIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-element ${className ?? ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02513 3.24583C7.6815 2.58945 8.57174 2.2207 9.5 2.2207C10.4283 2.2207 11.3185 2.58945 11.9749 3.24583C12.6313 3.90221 13 4.79245 13 5.7207C13 6.64896 12.6313 7.5392 11.9749 8.19558C11.3185 8.85195 10.4283 9.2207 9.5 9.2207C8.57174 9.2207 7.6815 8.85195 7.02513 8.19558C6.36875 7.5392 6 6.64896 6 5.7207C6 4.79245 6.36875 3.90221 7.02513 3.24583ZM9.5 3.2207C8.83696 3.2207 8.20107 3.4841 7.73223 3.95294C7.26339 4.42178 7 5.05766 7 5.7207C7 6.38374 7.26339 7.01963 7.73223 7.48847C8.20107 7.95731 8.83696 8.2207 9.5 8.2207C10.163 8.2207 10.7989 7.95731 11.2678 7.48847C11.7366 7.01963 12 6.38374 12 5.7207C12 5.05766 11.7366 4.42178 11.2678 3.95294C10.7989 3.4841 10.163 3.2207 9.5 3.2207Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5357 9.67794C10.8116 9.33448 10.0124 9.17981 9.21248 9.22832C8.41254 9.27683 7.63788 9.52694 6.9606 9.95539C6.28333 10.3838 5.72547 10.9767 5.33896 11.6787C4.95244 12.3808 4.74983 13.1692 4.75 13.9706V14.7207C4.75 14.9968 4.52614 15.2207 4.25 15.2207C3.97386 15.2207 3.75 14.9968 3.75 14.7207V13.9708C3.75 13.9708 3.75 13.9708 3.75 13.9708C3.74982 13.0007 3.99508 12.0462 4.46295 11.1964C4.93084 10.3466 5.60614 9.62893 6.42599 9.11029C7.24585 8.59165 8.1836 8.28887 9.15195 8.23015C10.1203 8.17143 11.0878 8.35867 11.9643 8.77444C12.2138 8.89278 12.3201 9.19098 12.2018 9.44047C12.0834 9.68997 11.7852 9.79629 11.5357 9.67794ZM14.0001 11.0332L13.4285 12.2452C13.3575 12.3957 13.2163 12.5011 13.0518 12.5262L11.7275 12.7285L12.7002 13.7203C12.8117 13.834 12.8624 13.994 12.8367 14.1511L12.6125 15.5222L13.759 14.8916C13.9091 14.8091 14.0909 14.8091 14.241 14.8916L15.3876 15.5223L15.1633 14.1512C15.1376 13.994 15.1883 13.834 15.2998 13.7203L16.2721 12.729L14.9488 12.5262C14.7844 12.501 14.6433 12.3957 14.5724 12.2454L14.0001 11.0332ZM16.6921 12.7933C16.6917 12.7933 16.6914 12.7932 16.691 12.7932ZM15.4603 15.9671C15.4604 15.9673 15.4603 15.9669 15.4603 15.9671ZM11.44 12.4354C11.44 12.4353 11.4401 12.4355 11.44 12.4354ZM13.5822 9.84474C13.7066 9.76402 13.8517 9.72107 14 9.72107C14.1483 9.72107 14.2934 9.76402 14.4178 9.84474C14.5404 9.92424 14.6377 10.037 14.6984 10.1698L15.3631 11.578L16.8414 11.8045C16.8417 11.8046 16.8411 11.8045 16.8414 11.8045M13.5822 9.84474C13.4596 9.92427 13.3623 10.0371 13.3016 10.17Z"
        fill={fill}
      />
    </svg>
  );
}
