import styled, { css } from "styled-components";
import { colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import { type ButtonCardModifier } from "_types/ButtonCard";

const modifier = {
  variant: {
    primary: css`
      background-color: transparent;
      color: ${colors.white};
      border-color: ${colors.white};
      :hover {
        color: ${colors.primary};
        border-color: ${colors.primary};
        .fill-element path {
          fill: ${colors.primary};
        }
        .stroke-element path {
          stroke: ${colors.primary};
        }
      }
      :active {
        background-color: ${colors.primary};
        color: ${colors.secondary};
        .fill-element path {
          fill: ${colors.secondary};
        }
        .stroke-element path {
          stroke: ${colors.secondary};
        }
      }
    `,
    danger: css`
      background-color: transparent;
      color: ${colors.white};
      border-color: ${colors.white};
      [active]:hover {
        color: ${colors.primary};
        border-color: ${colors.primary};
        .fill-element path {
          fill: ${colors.primary};
        }
        .stroke-element path {
          stroke: ${colors.primary};
        }
      }
      :active {
        background-color: ${colors.danger};
        border-color: ${colors.danger};
        color: ${colors.white};
        .fill-element path {
          fill: ${colors.white};
        }
        .stroke-element path {
          stroke: ${colors.white};
        }
      }
    `,
    contrast: css`
      background-color: ${colors.primary};
      color: ${colors.white};
      border-color: ${colors.primary};
    `
  }
};

export const ContainerButtonCard = styled.button<ButtonCardModifier>`
  ${({ variant }) => css`
    padding: 15px 20px;
    border-radius: 48px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    transition: all 0.3s;
    cursor: pointer;
    svg {
      transition: all 0.3s;
    }
    .button__text {
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(17)};
      letter-spacing: 0.3px;
      font-weight: 400;
    }
    :hover {
      transform: translateY(-2px);
    }
    :disabled {
      cursor: auto;
      transform: translateY(0);
    }
    ${modifier.variant[variant]}
  `}
`;
