import React from "react";
import { onHandleUser } from "config/store/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAuthGoogle } from "services/loginGoogle";
import { constants } from "utils/constants";
import { type useLoginData } from "_types/Login";

export function useLogin(): useLoginData {
  const initialStateModalInfos = {
    isOpenAlert: false,
    title: "",
    description: ""
  };
  const [modalInfos, setModalInfos] = React.useState(initialStateModalInfos);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function onLoginGoogle(): Promise<void> {
    try {
      const result = await loginAuthGoogle();
      const email = result?.user?.email;
      const payloadSlice = {
        name: result?.user?.displayName,
        email,
        photoURL: result?.user?.photoURL,
        isAuthenticated: true
      };
      if (email?.endsWith("@gok.digital") === true) {
        navigate(constants.PATHS.home);
        dispatch(onHandleUser(payloadSlice));
      } else {
        setModalInfos({
          isOpenAlert: true,
          title: "Ops!!",
          description: "Você precisa entrar com uma conta de e-mail GO-K!"
        });
      }
    } catch (error) {
      setModalInfos({
        isOpenAlert: true,
        title: "Ops!!",
        description: "Parece que algo deu arrado. Tente novamente!"
      });
    }
  }

  return {
    onLoginGoogle,
    onCloseAlert: () => {
      setModalInfos(initialStateModalInfos);
    },
    modalInfos
  };
}
