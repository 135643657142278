import styled, { css } from "styled-components";
import { breakPoints, colors, safeArea } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import { type TitleSectionModifier } from "_types/Top10";

const modifier = {
  new: css`
    .switch__item--new {
      color: ${colors.secondary};
    }
    .switch__contrast {
      left: 8px;
      width: 130px;
    }
  `,
  hot: css`
    .switch__item--hot {
      color: ${colors.secondary};
    }
    .switch__contrast {
      left: 138px;
      width: 152px;
    }
  `
};

export const ContainerTitleSection = styled.div<TitleSectionModifier>`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .section__title {
    color: ${colors.white};
    letter-spacing: 0.3px;
    font-weight: 300;
    text-align: center;
  }
  .section__description {
    color: ${colors.white};
    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(40)};
  }
  .section__switch {
    border: 1px solid ${colors.white};
    padding: 8px;
    border-radius: 48px;
    width: 300px;
    position: relative;
    .switch__item {
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(17)};
      padding: 14px 0;
      border-radius: 48px;
      color: ${colors.white};
      background-color: transparent;
      cursor: pointer;
      z-index: 1;
      position: sticky;
      transition-delay: 0.2s;
      transition: all 0.2s;
      :disabled {
        cursor: auto;
      }
    }
    .switch__item--new {
      width: 130px;
    }
    .switch__item--hot {
      width: 150px;
    }

    .switch__contrast {
      z-index: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      height: 48px;
      width: 100%;
      border-radius: 48px;
      background-color: ${colors.white};
      transition: all 0.3s;
    }
    ${({ type }) => modifier[type]}
  }
  @media screen and (max-width: ${breakPoints.small}) {
    margin-top: 56px;
    .section__title {
      font-size: ${pxToRem(40)};
    }
    .section__description {
      margin-top: 24px;
    }
    .section__switch {
      margin-top: 32px;
    }
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    margin-top: 104px;
    margin-inline: auto;
    max-width: ${safeArea};
    .section__title {
      font-size: ${pxToRem(64)};
      line-height: ${pxToRem(40)};
    }
    .section__description {
      margin-top: 32px;
    }
    .section__switch {
      margin-top: 40px;
    }
  }
`;
