import styled from "styled-components";
import { colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";

export const Container = styled.div`
  display: flex;
  background-color: ${colors.lightGreen};
  justify-content: space-between;

  .collumn {
    background-color: ${colors.secondary};
    min-width: 30%;
    height: ${pxToRem(52)};
  }

  div:first-child {
    border-radius: 0 ${pxToRem(52)} 0 0;
  }

  div:last-child {
    border-radius: ${pxToRem(52)} 0 0 0;
  }

  div {
    display: flex;
    min-width: 30%;
    justify-content: space-evenly;
  }

  a {
    :hover {
      transform: translateY(-2px);
    }
  }
`;
