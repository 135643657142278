import styled, { css } from "styled-components";
import { colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import { type TagsModifier } from "_types/Tags";

const modifier = {
  variant: {
    hot: css`
      color: ${colors.orange};
      border-color: ${colors.orange};
      padding: 2px 13px;
    `,
    new: css`
      color: ${colors.primary};
      border-color: ${colors.primary};
      padding: 2px 15px;
    `
  }
};

export const ContainerTags = styled.span<TagsModifier>`
  ${({ variant }) => css`
    border-radius: 42px;
    border-width: 1px;
    border-style: solid;
    font-size: ${pxToRem(10)};
    line-height: ${pxToRem(20)};
    letter-spacing: 0.3px;
    font-weight: 500;
    ${modifier.variant[variant]}
  `}
`;
