import React from "react";
import { getProducts } from "services/hooks/useProducts";
import { getWishlist } from "services/hooks/useWishilist";
import { useDispatch, useSelector } from "react-redux";
import type { tabsOptions, useShowcaseData } from "_types/Showcase";
import { selectUser } from "config/store/slices/userSlice";
import {
  onPopulateProducts,
  selectProducts
} from "config/store/slices/productSlice";
import {
  onHandleAwaitRequestProduct,
  selectAwaitRequestProduct
} from "config/store/slices/awaitRequestProductSlice";

export function useShowcase(): useShowcaseData {
  const { email } = useSelector(selectUser);
  const { showcase, wishlist } = useSelector(selectProducts);
  const { isAwaitRequestProduct } = useSelector(selectAwaitRequestProduct);
  const dispatch = useDispatch();
  const [currenTab, setCurrenTab] = React.useState<tabsOptions>("showcase");
  const [isShowMore, setIsShowMore] = React.useState<boolean>(false);
  const isShowcase = currenTab === "showcase";
  const isList = currenTab === "list";

  function onChangeTab(value: tabsOptions): void {
    setCurrenTab(value);
  }

  const getDataShowCase = React.useCallback(
    async ({ pageSize }: { pageSize: number }) => {
      try {
        dispatch(
          onHandleAwaitRequestProduct({
            isAwaitRequestProduct: true
          })
        );
        const [resultListProducts, resultWishlist] = await Promise.all([
          getProducts({ pageSize }),
          getWishlist({ email: email ?? "" })
        ]);
        dispatch(
          onPopulateProducts({
            products: resultListProducts.data,
            wishlist: resultWishlist.data
          })
        );
      } catch (error) {
      } finally {
        dispatch(
          onHandleAwaitRequestProduct({
            isAwaitRequestProduct: false
          })
        );
      }
    },
    []
  );

  function onShowMore(): void {
    setIsShowMore(true);
    getDataShowCase({ pageSize: 50 });
  }

  React.useEffect(() => {
    getDataShowCase({ pageSize: 30 });
  }, []);

  return {
    onChangeTab,
    onShowMore,
    isDisabledButtonShowcase: isShowcase,
    isDisabledButtonList:
      isList || wishlist.length === 0 || isAwaitRequestProduct,
    currenTab,
    showcase,
    wishlist,
    isShowMore,
    isAwaitRequestProduct
  };
}
