import React from "react";
import { Tags, Button } from "components";
import { type ProductCardProps } from "_types/ProductCard";
import { type tagsVariant } from "_types/Tags";
import { ContainerProductCard } from "./style";
import { useProductCard } from "./useProductCard";

export function ProductCard({
  variant = "normal",
  data,
  typeList
}: ProductCardProps): JSX.Element {
  const {
    onChangeVariant,
    onAddFavorite,
    onRemoveFavorite,
    variantSelected,
    imagesVariants,
    payloadAddFavorite,
    isAwaitRequest
  } = useProductCard({
    product: data
  });
  const isFavorite = data?.favorits.length > 0;
  const existVariant = imagesVariants.length > 1;
  const {
    position: positionVariantSelected,
    nameVariant: nameVariantSelected
  } = variantSelected;
  const isTabShowCase = typeList === "showcase";
  const isTabWishlist = typeList === "list";
  const isShouldRenderOptionsColors = existVariant && isTabShowCase;
  const isAlreadyFavorite =
    typeList === "showcase" &&
    data?.favorits.some((item: any) => item.variant === nameVariantSelected);
  const dataTag = {
    variant: data.attributes.featured === true ? "hot" : "new",
    label: data.attributes.featured === true ? "HOT" : "Novidade"
  };
  return (
    <ContainerProductCard
      key={data.id}
      variant={variant}
      productSelected={positionVariantSelected}
    >
      <div className="product__image">
        <div className="image__switch">
          {imagesVariants.map((item: any, index: number) => (
            <img
              key={index}
              src={item.photo}
              alt="Produto GO-K"
              className="image__item"
              loading="lazy"
            />
          ))}
        </div>
      </div>
      <div className="product__data">
        <div className="product__header">
          <p className="header__name">{data.attributes.name}</p>
          <Tags
            variant={dataTag.variant as tagsVariant}
            label={dataTag.label}
          />
        </div>
        <p className="header__description">{data.attributes.description}</p>
        <div className="product__color-options">
          {isShouldRenderOptionsColors && (
            <React.Fragment>
              {imagesVariants.map((item, index) => {
                const isColorVariant =
                  item.positition === positionVariantSelected;
                const classItem = isColorVariant
                  ? "option__item option__item--selected"
                  : "option__item";
                return (
                  <button
                    key={index}
                    style={{ backgroundColor: item.hexadecimal }}
                    type="button"
                    className={classItem}
                    disabled={isColorVariant}
                    onClick={() => {
                      onChangeVariant({
                        position: index,
                        nameVariant: item.nameVariant
                      });
                    }}
                  />
                );
              })}
            </React.Fragment>
          )}
        </div>
        {isTabShowCase &&
          (isFavorite && isAlreadyFavorite === true ? (
            <Button
              label="Adicionado"
              variant="contrast"
              disabled={true}
              iconName="DoneIcon"
              className="product__button"
            />
          ) : (
            <Button
              label={isAwaitRequest ? "Aguarde" : "Lista de desejo"}
              variant="primary"
              disabled={isAwaitRequest}
              iconName={isAwaitRequest ? "DotsIcon" : "FavoriteIcon"}
              onClick={() => {
                onAddFavorite(payloadAddFavorite);
              }}
              className="product__button"
            />
          ))}
        {isTabWishlist && (
          <Button
            label={isAwaitRequest ? "Aguarde" : "Remover"}
            variant="danger"
            disabled={isAwaitRequest}
            iconName={isAwaitRequest ? "DotsIcon" : "TrashIcon"}
            onClick={() => {
              onRemoveFavorite({
                idProduct: data.id,
                idFavorite: data.idFavorite,
                variant: data?.favorits[0].variant
              });
            }}
            className="product__button"
          />
        )}
      </div>
    </ContainerProductCard>
  );
}
