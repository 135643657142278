import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
  Navigate
} from "react-router-dom";
import { PublicRoute } from "./public";
import { constants } from "utils/constants";
import { authPaths, publicPaths } from "./paths";
import { Error } from "screens/public";
import { PrivateRoute } from "./auth";
import { LandingPage } from "Layout/LandingPage";
import { useSelector } from "react-redux";
import { selectUser } from "config/store/slices/userSlice";

export function Routes(): JSX.Element {
  const { isAuthenticated } = useSelector(selectUser);
  const shouldRetirectTo = isAuthenticated
    ? constants.PATHS.home
    : constants.PATHS.login;
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route
          path="/"
          element={
            <PrivateRoute isAuth={isAuthenticated}>
              <LandingPage />
            </PrivateRoute>
          }
        >
          {authPaths.map(item => {
            const Component = item.screen;
            return (
              <Route key={item.id} path={item.path} element={<Component />} />
            );
          })}
        </Route>

        {publicPaths.map(item => {
          const Component = item.screen;
          return (
            <Route
              key={item.id}
              path={item.path}
              element={
                <PublicRoute isAuth={isAuthenticated}>
                  <Component />
                </PublicRoute>
              }
            />
          );
        })}
        <Route
          path={constants.PATHS.error}
          element={
            <PublicRoute isAuth={isAuthenticated}>
              <Error />
            </PublicRoute>
          }
        />
        <Route path="*" element={<Navigate to={shouldRetirectTo} replace />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
}
