import { type DefaultIconProps } from "_types/Icons";

export function TwitterIcon({ className }: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        opacity="0.15"
        x="1.66406"
        y="0.75"
        width="36.3647"
        height="36.3646"
        rx="18.1823"
        stroke="#181818"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6637 16.4336L19.691 16.8955L19.235 16.839C17.5752 16.6221 16.1252 15.8868 14.894 14.6519L14.2921 14.0391L14.1371 14.4916C13.8087 15.5003 14.0185 16.5656 14.7025 17.2821C15.0673 17.678 14.9852 17.7346 14.3559 17.4989C14.1371 17.4235 13.9455 17.3669 13.9273 17.3952C13.8635 17.4612 14.0823 18.3191 14.2556 18.6584C14.4927 19.1298 14.9761 19.5917 15.505 19.8651L15.9519 20.0819L15.423 20.0914C14.9122 20.0914 14.894 20.1008 14.9487 20.2988C15.1311 20.9115 15.8516 21.562 16.6541 21.8448L17.2196 22.0428L16.7271 22.3445C15.9975 22.7781 15.1402 23.0232 14.283 23.0421C13.8726 23.0515 13.5352 23.0892 13.5352 23.1175C13.5352 23.2118 14.6478 23.7397 15.2953 23.9471C17.2378 24.5599 19.5451 24.2959 21.2779 23.2495C22.509 22.5047 23.7402 21.0247 24.3147 19.5917C24.6248 18.8281 24.9349 17.4329 24.9349 16.7636C24.9349 16.3299 24.9623 16.2733 25.473 15.7548C25.7739 15.4532 26.0566 15.1232 26.1113 15.0289C26.2025 14.8498 26.1934 14.8498 25.7283 15.0101C24.9531 15.2929 24.8437 15.2552 25.2267 14.831C25.5094 14.5293 25.8469 13.9825 25.8469 13.8223C25.8469 13.794 25.7101 13.8411 25.555 13.926C25.3909 14.0202 25.0261 14.1616 24.7525 14.2465L24.26 14.4067L23.8132 14.0956C23.5669 13.926 23.2204 13.7374 23.038 13.6809C22.5729 13.5489 21.8615 13.5677 21.442 13.7186C20.302 14.1428 19.5816 15.2363 19.6637 16.4336Z"
        fill="#181818"
      />
    </svg>
  );
}
