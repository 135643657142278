import styled from "styled-components";
import { safeArea } from "styled/theme";

export const ContainerShowcase = styled.section`
  display: grid;
  padding-inline: 16px;
  align-items: end;
  .showcase__button {
    width: fit-content;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 40px;
    .showcase__button {
      margin: 40px auto 0;
      grid-column: 1 / span 1;
    }
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 60px;
    column-gap: 30px;
    .showcase__button {
      grid-column: 1 / span 2;
      margin: 32px auto 0;
    }
  }
  @media screen and (min-width: 1025px) {
    grid-template-columns: repeat(4, 1fr);
    max-width: ${safeArea};
    margin-inline: auto;
    margin-top: 60px;
    column-gap: 30px;
    .showcase__button {
      grid-column: 1 / span 4;
      margin: 32px auto 0;
    }
  }
`;
