import { PresentationSession, Showcase, Top10, VirtualStore } from "components";
import React from "react";
import { ContainerHome } from "./style";

export function Home(): JSX.Element {
  return (
    <ContainerHome>
      <PresentationSession />
      <VirtualStore />
      <Top10 />
      <Showcase />
    </ContainerHome>
  );
}
