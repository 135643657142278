import { apiToken, baseAPI } from "services/api";
import { PRODUCTS_LESS_QUANTY } from "services/api/endpoints";
import { type RequestProductsData } from "_types/Products";

export async function getProducts({
  pageSize = 8
}: {
  pageSize?: number;
}): Promise<RequestProductsData> {
  const response = await baseAPI.get<RequestProductsData>(
    `${PRODUCTS_LESS_QUANTY}?populate=*&pagination%5BpageSize%5D=${pageSize}`,
    apiToken
  );
  return response.data;
}
