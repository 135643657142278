import styled from "styled-components";
import { breakPoints, colors } from "styled/theme";

export const Container = styled.section`
  width: calc(100% - 98px);
  margin-left: 98px;
  background: ${colors.linearBackground};
  @media screen and (max-width: ${breakPoints.mobile}) {
    width: 100%;
    margin-left: 0px;
  }
`;
