import React from "react";
import { ContainerShirts } from "./style";
import Model1 from "assets/images/model1.webp";
import Model2 from "assets/images/model2.webp";

export function Shirts(): JSX.Element {
  const shirst = {
    modale1: 1,
    modale2: 2
  };
  const [shirtContrast, setShirtContrast] = React.useState(shirst.modale1);
  function onChangeOrder(model: number): void {
    if (model !== shirtContrast) {
      setShirtContrast(model);
    }
  }

  function getClass(model: number): string {
    if (model === shirtContrast) {
      return "roll__item roll__item--front";
    }
    return "roll__item roll__item--back";
  }

  return (
    <ContainerShirts>
      <div className="shirts__roll">
        <img
          src={Model1}
          alt="Modelo 1, moleton preto"
          className={getClass(1)}
          onClick={() => {
            onChangeOrder(shirst.modale1);
          }}
        />
        <img
          src={Model2}
          alt="Modelo 2, moleton branco"
          className={getClass(2)}
          onClick={() => {
            onChangeOrder(shirst.modale2);
          }}
        />
      </div>
    </ContainerShirts>
  );
}
