import React from "react";
import {
  Box,
  Buttoncancel,
  ButtonEnviar,
  Inputmodal,
  Counter,
  Side
  // Upload
} from "./styles";
import Modal from "react-modal";
import exit from "assets/icons/exit.svg";
import { Alert } from "components/Alert";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { selectUser } from "config/store/slices/userSlice";
import { postSugestion } from "services/hooks/useSugestion";

export function SuggestionModal({
  isOpen,
  onHandleModal
}: {
  isOpen: boolean;
  onHandleModal: () => void;
}): JSX.Element {
  const [isAwaitRequest, setIsAwaitRequest] = React.useState<boolean>(false);
  const { email } = useSelector(selectUser);
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
  const [dataDialog, setDataDialog] = React.useState({
    title: "Oba, sugestão enviada!",
    description:
      "Só aguardar que iremos analisar a sua sugestão, obrigado(a) por participar!"
  });

  function onHandleDialog(): void {
    setIsOpenDialog(!isOpenDialog);
  }

  async function onSubmtForm({
    name,
    description
  }: {
    name: string;
    description: string;
  }): Promise<void> {
    try {
      setIsAwaitRequest(true);
      const payload = {
        name,
        description,
        user: email ?? ""
      };
      await postSugestion({ payload });
      setDataDialog({
        title: "Oba, sugestão enviada!",
        description:
          "Só aguardar que iremos analisar a sua sugestão, obrigado(a) por participar!"
      });
    } catch (error) {
      setDataDialog({
        title: "Opss!!",
        description: "Algo deu errado, tente novamente!"
      });
    } finally {
      onHandleDialog();
      onHandleModal();
      setIsAwaitRequest(false);
    }
  }

  return (
    <div>
      <Alert
        title={dataDialog.title}
        description={dataDialog.description}
        titleAction="Fechar"
        onAction={onHandleDialog}
        type="success"
        isOpen={isOpenDialog}
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={onHandleModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0 ,0, 0.8)"
          },
          content: {
            border: "none",
            background: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }
        }}
      >
        <Form
          onSubmit={values => {
            onSubmtForm({
              name: values.name,
              description: values.description
            });
          }}
          initialValues={{
            name: "",
            description: ""
          }}
          render={({ handleSubmit, values }) => (
            <Box className="boxstyle" onSubmit={handleSubmit}>
              <Side>
                <h1 className="title">
                  Enviar <strong>minhas sugestões</strong>
                </h1>

                <Buttoncancel onClick={onHandleModal}>
                  <img src={exit} alt="sair" />
                </Buttoncancel>
              </Side>
              <div className="sideQuestionWeb">
                <h1 className="questionWeb">Especificações</h1>
                <h1 className="question">Qual produto deseja sugerir?</h1>
              </div>
              <Field
                name="name"
                render={({ input }) => (
                  <Inputmodal
                    placeholder="Digite aqui nome do produto. Ex: Capinha de celular"
                    required
                    maxLength={50}
                    {...input}
                  />
                )}
              />
              <Side>
                <h1 className="question questionSide">
                  Tem alguma observação?
                </h1>
                <Counter>
                  <h5>{156 - values.description.length}</h5>
                </Counter>
              </Side>
              <Field
                name="description"
                render={({ input }) => (
                  <Inputmodal
                    placeholder="Ex: Capinha verde com logo do GO.K Valley em branco."
                    maxLength={156}
                    {...input}
                  />
                )}
              />
              {/* <h1 className="questionWeb">Fotos</h1>
              <h1 className="question">
                Deseja enviar alguma imagem de exemplo?
              </h1>
              <Upload>
            <div className="upload__body">
              <img src={uploadIcon} alt="sair" />
              <p className="questionUpload">Arraste e solte seu arquivo aqui</p>
              <p className="questionUpload">ou</p>

              <div className="upload__footer">
                <label htmlFor="upload__input" className="upload__button">
                  <p>Selecione um arquivo para enviar</p>
                  <input type="file" id="upload__input"></input>
                </label>
              </div>
            </div>
          </Upload> */}
              <ButtonEnviar
                type="submit"
                disabled={
                  values.name.length === 0 ||
                  values.description.length === 0 ||
                  isAwaitRequest
                }
              >
                {isAwaitRequest ? "Aguarde..." : "Enviar sugestão"}
              </ButtonEnviar>
            </Box>
          )}
        />
      </Modal>
    </div>
  );
}
