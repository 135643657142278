import styled from "styled-components";
import { breakPoints, colors, safeArea } from "styled/theme";
import { pxToRem } from "utils/pxToRem";

export const ContainerVirtualStore = styled.section`
  padding: 16px;
  margin: auto;
  max-width: ${safeArea};
  .store__title {
    color: ${colors.light};
    letter-spacing: 0.3px;
    text-align: center;
    .title__contrast {
      color: ${colors.lemonGreen};
    }
  }

  @media screen and (max-width: ${breakPoints.small}) {
    padding-top: 36px;
    .store__title {
      font-size: ${pxToRem(28)};
      line-height: ${pxToRem(48)};
    }
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    padding-top: 92px;
    .store__title {
      font-size: ${pxToRem(32)};
      line-height: ${pxToRem(40)};
    }
  }
`;

export const StoreCards = styled.div`
  display: grid;
  gap: 32px;
  align-items: center;
  .card__item {
    background-color: ${colors.secondary};
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 24px;
    height: fit-content;
    .item__icon {
      width: 69px;
      height: 68px;
    }
    .item__title {
      color: ${colors.white};
      font-size: ${pxToRem(24)};
      line-height: ${pxToRem(20)};
      text-align: center;
    }
    .item__description {
      color: ${colors.light};
      font-size: ${pxToRem(18)};
      line-height: ${pxToRem(24)};
      text-align: center;
      font-weight: 300;
    }
  }

  @media screen and (max-width: 877px) {
    grid-template-columns: 1fr;
    margin-top: 36px;
    .card__item {
      padding: 35px 10px;
    }
  }
  @media screen and (min-width: 878px) {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 48px;

    .card__item {
      padding: 45px 30px;
    }
    .card__item--contrast {
      padding-top: 55px;
      padding-bottom: 55px;
    }
  }
`;
