import { HandHeartIcon, SmileIcon, UserStarIcon } from "assets/icons";
import React from "react";
import { colors } from "styled/theme";
import { ContainerVirtualStore, StoreCards } from "./style";

export function VirtualStore(): JSX.Element {
  return (
    <ContainerVirtualStore>
      <h2 className="store__title">
        Você já conhece a nossa
        <span className="title__contrast"> Loja virtual</span>?
      </h2>
      <StoreCards>
        <div className="card__item">
          <UserStarIcon fill={colors.lemonGreen} className="item__icon" />
          <h3 className="item__title">Super exclusivo</h3>
          <p className="item__description">
            Somente <strong className="description__contrast">Gokers</strong>{" "}
            terão acesso aos nossos produtos.
          </p>
        </div>
        <div className="card__item card__item--contrast">
          <HandHeartIcon fill={colors.lemonGreen} className="item__icon" />
          <h3 className="item__title">Fazendo o bem</h3>
          <p className="item__description">
            Parte da arrecadação das vendas vai ser doada para {`ONG's`}.
          </p>
        </div>
        <div className="card__item">
          <SmileIcon fill={colors.lemonGreen} className="item__icon" />
          <h3 className="item__title">Produtos incríveis</h3>
          <p className="item__description">
            Tenha produtos incríveis e ainda ande no estilo GO.K.
          </p>
        </div>
      </StoreCards>
    </ContainerVirtualStore>
  );
}
