import { css } from "styled-components";
import { pxToRem } from "utils/pxToRem";
import { breakPoints, safeArea } from "./theme";

export const LayoutGridSpace = css`
  display: grid;
  @media screen and (max-width: ${breakPoints.small}) {
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    column-gap: ${pxToRem(16)};
  }
`;

export const LayoutGridClean = css`
  ${LayoutGridSpace};
  @media screen and (max-width: ${breakPoints.small}) {
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const LayoutGrid = css`
  ${LayoutGridClean};
  padding-inline: 16px;
  @media screen and (max-width: ${breakPoints.small}) {
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    margin-inline: auto;
    max-width: ${safeArea};
  }
`;
