import { type DefaultIconProps } from "_types/Icons";

export function IconExitMobile({
  className,
  fill = "#C6C6C6"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7654 14.3571L17.5004 10.5M17.5004 10.5L13.7654 6.64286M17.5004 10.5H5.92899M13.6433 1.5L3.35756 1.50257C1.93813 1.50386 0.786133 2.65457 0.786133 4.074V16.9247C0.786133 17.6067 1.05705 18.2608 1.53929 18.743C2.02152 19.2252 2.67558 19.4961 3.35756 19.4961H13.7654"
        stroke={fill}
      />
    </svg>
  );
}
