import React from "react";
import { Top } from "./partials/Top";
import { useShowcase } from "./useShowcase";
import { ContainerShowcase } from "./style";
import { ProductCard } from "components/ProductCard";
import { SkeletonProductCard } from "components/SkeletonProductCard";
import { MultSkeleton } from "components/Skeleton";

export function Showcase(): JSX.Element {
  const {
    isDisabledButtonShowcase,
    isDisabledButtonList,
    currenTab,
    showcase,
    wishlist,
    isAwaitRequestProduct,
    onChangeTab
  } = useShowcase();
  const shouldRenderListShowCase =
    currenTab === "showcase" && !isAwaitRequestProduct;
  const shouldRenderWishlist = currenTab === "list" && !isAwaitRequestProduct;
  const shouldRenderSkeleton = isAwaitRequestProduct;
  return (
    <React.Fragment>
      <Top
        isDisabledButtonShowcase={isDisabledButtonShowcase}
        isDisabledButtonList={isDisabledButtonList}
        currenTab={currenTab}
        onChangeTab={onChangeTab}
      />
      <ContainerShowcase>
        {shouldRenderListShowCase &&
          showcase.map(item => (
            <ProductCard
              typeList={currenTab}
              key={item.id}
              data={item}
              variant="burst"
            />
          ))}
        {shouldRenderWishlist &&
          wishlist.map(item => (
            <ProductCard
              typeList={currenTab}
              key={item.idFavorite}
              data={item}
              variant="burst"
            />
          ))}
        {shouldRenderSkeleton && (
          <MultSkeleton amount={10}>
            <SkeletonProductCard variant="burst" />
          </MultSkeleton>
        )}
      </ContainerShowcase>
    </React.Fragment>
  );
}
