import { type DefaultIconProps } from "_types/Icons";

export function FacebookIcon({ className }: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        opacity="0.15"
        x="0.75"
        y="0.75"
        width="36.3646"
        height="36.3646"
        rx="18.1823"
        stroke="#181818"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0398 26.2141V18.9318H15.5342V16.4222H17.0398V14.9154C17.0398 12.8681 17.8899 11.6506 20.3053 11.6506H22.3161V14.1605H21.0592C20.119 14.1605 20.0568 14.5113 20.0568 15.1658L20.0533 16.4219H22.3304L22.0639 18.9315H20.0533V26.2141H17.0398Z"
        fill="#181818"
      />
    </svg>
  );
}
