import { type DefaultIconProps } from "_types/Icons";

export function SmileIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-element ${className ?? ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 17C13.9183 17 17.5 13.4183 17.5 9C17.5 4.58172 13.9183 1 9.5 1C5.08172 1 1.5 4.58172 1.5 9C1.5 13.4183 5.08172 17 9.5 17ZM9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18Z"
        fill={fill}
      />
      <path
        d="M6.70452 4.05115C6.68044 3.99913 6.64198 3.95509 6.59368 3.92422C6.54538 3.89336 6.48925 3.87695 6.43193 3.87695C6.3746 3.87695 6.31848 3.89336 6.27017 3.92422C6.22187 3.95509 6.18341 3.99913 6.15933 4.05115L5.53084 5.36492L4.08836 5.55422C3.97099 5.56936 3.87634 5.64887 3.83848 5.76245C3.80062 5.87603 3.83469 5.99719 3.91798 6.07669L4.97429 7.08L4.70927 8.51113C4.68655 8.62471 4.73577 8.74208 4.83042 8.81023C4.92508 8.87838 5.05002 8.88595 5.15224 8.83294L6.43193 8.13631L7.71161 8.82916C7.81384 8.88595 7.93878 8.87838 8.03343 8.80644C8.12808 8.73451 8.17351 8.62092 8.15458 8.50734L7.88956 7.07621L8.94587 6.07291C9.02916 5.9934 9.06324 5.87225 9.02538 5.75867C8.98752 5.64508 8.89286 5.56558 8.7755 5.55043L7.33301 5.36113L6.70452 4.04737V4.05115ZM12.7622 4.05115C12.7381 3.99913 12.6997 3.95509 12.6514 3.92422C12.6031 3.89336 12.5469 3.87695 12.4896 3.87695C12.4323 3.87695 12.3762 3.89336 12.3279 3.92422C12.2796 3.95509 12.2411 3.99913 12.217 4.05115L11.5885 5.36492L10.146 5.55422C10.0287 5.56936 9.93403 5.64887 9.89617 5.76245C9.85831 5.87603 9.89238 5.99719 9.97568 6.07669L11.032 7.08L10.767 8.51113C10.7442 8.62471 10.7935 8.74208 10.8881 8.81023C10.9828 8.87838 11.1077 8.88595 11.2099 8.83294L12.4896 8.13631L13.7693 8.82916C13.8715 8.88595 13.9965 8.87838 14.0911 8.80644C14.1858 8.73451 14.2312 8.62092 14.2123 8.50734L13.9473 7.07621L15.0036 6.07291C15.0869 5.9934 15.1209 5.87225 15.0831 5.75867C15.0452 5.64508 14.9506 5.56558 14.8332 5.55043L13.3907 5.36113L12.7622 4.04737V4.05115ZM13.0007 10.707C11.9066 10.9645 10.7102 11.1046 9.45699 11.1046C8.2038 11.1046 7.00741 10.9645 5.91324 10.707C5.20524 10.5405 4.55404 11.1614 4.94779 11.7709C5.87916 13.2134 7.54881 14.1751 9.45699 14.1751C11.3652 14.1751 13.0348 13.2134 13.9662 11.7709C14.3599 11.1614 13.7087 10.5405 13.0007 10.707Z"
        fill={fill}
      />
    </svg>
  );
}
