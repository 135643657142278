import { breakPoints, colors } from "styled/theme";
import styled from "styled-components";
import { pxToRem } from "utils/pxToRem";

export const ContainerError = styled.main`
  display: flex;
  flex: 1;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: ${colors.secondary};
  color: ${colors.white};

  @media screen and (max-width: ${breakPoints.small}) {
    flex-direction: column-reverse;
    text-align: center;
    .img_woman {
      width: 90%;
    }
    .text_error {
      display: none;
    }
    .text_info {
      font-size: ${pxToRem(25)};
      max-width: 20rem;
    }
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    .img_woman {
      width: 40rem;
    }
    .text_1 {
      font-size: ${pxToRem(40)};
      padding-bottom: 1rem;
      text-transform: uppercase;
    }
    .text_2 {
      font-size: ${pxToRem(20)};
    }
    .text_2::first-letter {
      text-transform: uppercase;
    }
    .text_info {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ContainerText = styled.div`
  justify-content: center;
  h1 {
    font-weight: 100;
    font-size: ${pxToRem(40)};
    padding-bottom: 1rem;
  }
  h3 {
    font-weight: 120;
  }
  .return_text {
    color: ${colors.primary};
  }
  .return_home {
    background: none;
    color: ${colors.primary};
    padding: 0.8rem 2rem 0.8rem 2rem;
    align-items: center;
    border-radius: 2rem;
    border: 1.3px solid ${colors.primary};
  }
`;

export const Text = styled.div`
  margin: 4rem 0 4rem 0;
`;
