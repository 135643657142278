import { type DefaultIconProps } from "_types/Icons";

export function DotsIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-element ${className ?? ""}`}
    >
      <path
        d="M6.5 14C5.95 14 5.47933 13.804 5.088 13.412C4.696 13.0207 4.5 12.55 4.5 12C4.5 11.45 4.696 10.979 5.088 10.587C5.47933 10.1957 5.95 10 6.5 10C7.05 10 7.52067 10.1957 7.912 10.587C8.304 10.979 8.5 11.45 8.5 12C8.5 12.55 8.304 13.0207 7.912 13.412C7.52067 13.804 7.05 14 6.5 14ZM12.5 14C11.95 14 11.4793 13.804 11.088 13.412C10.696 13.0207 10.5 12.55 10.5 12C10.5 11.45 10.696 10.979 11.088 10.587C11.4793 10.1957 11.95 10 12.5 10C13.05 10 13.521 10.1957 13.913 10.587C14.3043 10.979 14.5 11.45 14.5 12C14.5 12.55 14.3043 13.0207 13.913 13.412C13.521 13.804 13.05 14 12.5 14ZM18.5 14C17.95 14 17.479 13.804 17.087 13.412C16.6957 13.0207 16.5 12.55 16.5 12C16.5 11.45 16.6957 10.979 17.087 10.587C17.479 10.1957 17.95 10 18.5 10C19.05 10 19.521 10.1957 19.913 10.587C20.3043 10.979 20.5 11.45 20.5 12C20.5 12.55 20.3043 13.0207 19.913 13.412C19.521 13.804 19.05 14 18.5 14Z"
        fill={fill}
      />
    </svg>
  );
}
