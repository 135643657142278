import { type DefaultIconProps } from "_types/Icons";

export function CloseIcon({
  className,
  fill = "#C6C6C6"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1257 17.53L1.06567 1.47M17.1257 1.47L1.06567 17.53"
        stroke={fill}
      />
    </svg>
  );
}
