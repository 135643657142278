import { Container } from "./styles";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "assets/icons";

export function Footer(): JSX.Element {
  return (
    <Container>
      <div className="collumn" />
      <div>
        <a
          href="https://www.facebook.com/gok.digital/"
          target={"_blank"}
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/gokdigital/"
          target={"_blank"}
          rel="noreferrer"
        >
          <LinkedinIcon />
        </a>
        <a
          href="https://twitter.com/GOK_Digital"
          target={"_blank"}
          rel="noreferrer"
        >
          <TwitterIcon />
        </a>
      </div>
      <div className="collumn" />
    </Container>
  );
}

export default Footer;
