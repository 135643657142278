import { type DefaultIconProps } from "_types/Icons";

export function DoneIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-element ${className ?? ""}`}
    >
      <path
        d="M16.8125 4.5L8.9375 13.5L5.5625 10.125M5.5625 13.5L2.1875 10.125M13.4375 4.5L8.65625 9.98438"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
