import React from "react";
import { Button } from "components/ButtonCard";
import { ContainerProducts } from "./style";
import type { ProductsProps } from "_types/Top10";
import { ProductCard } from "components/ProductCard";
import { navigateToSection } from "utils/navigateToSection";
import { useSelector } from "react-redux";
import { selectAwaitRequestProduct } from "config/store/slices/awaitRequestProductSlice";
import { MultSkeleton } from "components/Skeleton";
import { SkeletonProductCard } from "components/SkeletonProductCard";

export function Products({ type, products }: ProductsProps): JSX.Element {
  const textDescription =
    type === "new"
      ? "Confira os nossos produtos que são lançamentos."
      : "Confira os nossos produtos que são mais desejados pelos Gokers.";
  const { isAwaitRequestProduct } = useSelector(selectAwaitRequestProduct);
  const shouldRenderListProducts = !isAwaitRequestProduct;
  const shouldRenderSkeleton = isAwaitRequestProduct;
  return (
    <ContainerProducts>
      <div className="products__infos">
        <h3 className="infos__title">
          {type === "new" ? (
            <React.Fragment>
              Confira as <strong>novidades</strong>
            </React.Fragment>
          ) : (
            <React.Fragment>
              Os mais <strong>desejados</strong>
            </React.Fragment>
          )}
        </h3>
        <p className="infos__description">{textDescription}</p>
        <Button
          label="Ver mais"
          onClick={() => {
            navigateToSection("produtos");
          }}
          className="infos__button"
        />
      </div>
      <div className="products__list">
        <div className="list__content">
          {shouldRenderListProducts &&
            products.map(item => (
              <ProductCard
                typeList="showcase"
                key={item.id}
                data={item}
                variant="normal"
              />
            ))}
          {shouldRenderSkeleton && (
            <MultSkeleton amount={3}>
              <SkeletonProductCard variant="normal" />
            </MultSkeleton>
          )}
        </div>
      </div>
    </ContainerProducts>
  );
}
