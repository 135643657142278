import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import { user } from "./slices/userSlice";
import { products } from "./slices/productSlice";
import { awaitRequestProduct } from "./slices/awaitRequestProductSlice";

const persistUser = {
  key: "data-user",
  storage
};

const persistedUser = persistReducer(persistUser, user);

const store = configureStore({
  reducer: {
    user: persistedUser,
    products,
    awaitRequestProduct
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
