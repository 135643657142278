import { type DefaultIconProps } from "_types/Icons";

export function ExitIcon({
  className,
  fill = "#787878"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-element ${className ?? ""}`}
    >
      <path
        d="M22.5801 22.2858L28.6668 16M28.6668 16L22.5801 9.71433M28.6668 16H9.80966M22.3811 1.33337L5.61919 1.33756C3.30604 1.33966 1.42871 3.2149 1.42871 5.52804V26.4699C1.42871 27.5813 1.87021 28.6472 2.65607 29.4331C3.44194 30.2189 4.5078 30.6604 5.61919 30.6604H22.5801"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
