import { type DefaultIconProps } from "_types/Icons";

export function FavoriteIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-element ${className ?? ""}`}
    >
      <path
        d="M8.75 15.75L6.36875 13.6125C5.46875 12.8 4.697 12.075 4.0535 11.4375C3.4095 10.8 2.87825 10.2 2.45975 9.6375C2.04075 9.075 1.7345 8.53125 1.541 8.00625C1.347 7.48125 1.25 6.93125 1.25 6.35625C1.25 5.18125 1.64375 4.203 2.43125 3.4215C3.21875 2.6405 4.2 2.25 5.375 2.25C6.025 2.25 6.64375 2.3875 7.23125 2.6625C7.81875 2.9375 8.325 3.325 8.75 3.825C9.175 3.325 9.68125 2.9375 10.2687 2.6625C10.8562 2.3875 11.475 2.25 12.125 2.25C13.1375 2.25 13.9875 2.53425 14.675 3.10275C15.3625 3.67175 15.8312 4.3875 16.0812 5.25H14.4875C14.2625 4.75 13.9313 4.375 13.4938 4.125C13.0563 3.875 12.6 3.75 12.125 3.75C11.4875 3.75 10.9375 3.922 10.475 4.266C10.0125 4.6095 9.58125 5.0625 9.18125 5.625H8.31875C7.93125 5.0625 7.49075 4.6095 6.99725 4.266C6.50325 3.922 5.9625 3.75 5.375 3.75C4.6625 3.75 4.04675 3.99675 3.52775 4.49025C3.00925 4.98425 2.75 5.60625 2.75 6.35625C2.75 6.76875 2.8375 7.1875 3.0125 7.6125C3.1875 8.0375 3.5 8.52825 3.95 9.08475C4.4 9.64075 5.0125 10.2905 5.7875 11.034C6.5625 11.778 7.55 12.675 8.75 13.725C9.075 13.4375 9.45625 13.1063 9.89375 12.7313C10.3312 12.3563 10.6813 12.0438 10.9438 11.7938L11.1125 11.9625L11.4778 12.3285L11.8438 12.6937L12.0125 12.8625C11.7375 13.1125 11.3875 13.4217 10.9625 13.7902C10.5375 14.1592 10.1625 14.4875 9.8375 14.775L8.75 15.75ZM14 12.75V10.5H11.75V9H14V6.75H15.5V9H17.75V10.5H15.5V12.75H14Z"
        fill={fill}
      />
    </svg>
  );
}
