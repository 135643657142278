import { type DefaultIconProps } from "_types/Icons";

export function QuestionMarkIcon({
  className,
  fill = "#C6C6C6"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872C2.17187 14.5462 1.25 12.3206 1.25 10C1.25 7.67936 2.17187 5.45376 3.81282 3.81282C5.45376 2.17187 7.67936 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
        fill={fill}
      />
      <path
        d="M6.57058 7.2325C6.56887 7.27287 6.57543 7.31316 6.58987 7.3509C6.6043 7.38863 6.62631 7.42302 6.65452 7.45194C6.68274 7.48085 6.71657 7.5037 6.75394 7.51906C6.79131 7.53442 6.83143 7.54197 6.87183 7.54125H7.90308C8.07558 7.54125 8.21308 7.4 8.23558 7.22875C8.34808 6.40875 8.91058 5.81125 9.91308 5.81125C10.7706 5.81125 11.5556 6.24 11.5556 7.27125C11.5556 8.065 11.0881 8.43 10.3493 8.985C9.50808 9.59625 8.84183 10.31 8.88933 11.4688L8.89308 11.74C8.89439 11.822 8.92789 11.9002 8.98636 11.9577C9.04482 12.0153 9.12356 12.0475 9.20558 12.0475H10.2193C10.3022 12.0475 10.3817 12.0146 10.4403 11.956C10.4989 11.8974 10.5318 11.8179 10.5318 11.735V11.6037C10.5318 10.7062 10.8731 10.445 11.7943 9.74625C12.5556 9.1675 13.3493 8.525 13.3493 7.17625C13.3493 5.2875 11.7543 4.375 10.0081 4.375C8.42433 4.375 6.68933 5.1125 6.57058 7.2325ZM8.51683 14.4362C8.51683 15.1025 9.04808 15.595 9.77933 15.595C10.5406 15.595 11.0643 15.1025 11.0643 14.4362C11.0643 13.7462 10.5393 13.2613 9.77808 13.2613C9.04808 13.2613 8.51683 13.7462 8.51683 14.4362Z"
        fill={fill}
      />
    </svg>
  );
}
