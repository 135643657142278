import Logo from "assets/images/logoHome.svg";
import IconFavorite from "assets/icons/iconFavorite.svg";
import IconExit from "assets/icons/iconExit.svg";
import { useState } from "react";
import { ContainerDesktop, ContainerMobile } from "./style";
import { CloseDialog } from "components";
import { useDispatch, useSelector } from "react-redux";
import { onLogOut, selectUser } from "config/store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { constants } from "utils/constants";
import { colors } from "styled/theme";

import {
  LogoHeader,
  LogoHeaderDark,
  IconHeaderMobile,
  Home,
  Product,
  IconFavoriteBtn,
  CloseIcon,
  IconExitMobile
} from "assets/icons";
import ImgExemple from "assets/images/ImgExemple.svg";
import Exemple from "assets/images/Exemple4.svg";
import ImgExempleShadow from "assets/images/ImgExempleShadow.svg";
import { navigateToSection } from "utils/navigateToSection";

export function HeaderLeft(): JSX.Element {
  const [selectedButton, setSelectedButton] = useState(0);
  const [isActiveRightMenu, setIsActiveRightMenu] = useState(0);
  const [isActiveLink, setIsActiveLink] = useState(0);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useSelector(selectUser);

  function onHandleDialog(): void {
    setIsOpenDialog(!isOpenDialog);
  }

  function onExit(): void {
    dispatch(onLogOut());
    navigate(constants.PATHS.login);
  }

  function onActionLink({
    positionLink,
    to
  }: {
    positionLink: number;
    to: string;
  }): void {
    setIsActiveRightMenu(0);
    setIsActiveLink(positionLink);
    navigateToSection(to);
  }

  return (
    <>
      <ContainerDesktop>
        <CloseDialog
          isOpen={isOpenDialog}
          onAction={onExit}
          onCancel={onHandleDialog}
        />
        <div>
          <img src={Logo} alt="Logo Store-K" className="home__logo" />
        </div>
        <nav>
          <button
            type="button"
            key={0}
            className={selectedButton === 0 ? "buttonSelected" : ""}
            onClick={() => {
              setSelectedButton(0);
              navigateToSection("inicio");
            }}
          >
            <p>início</p>
            <span />
          </button>
          <button
            type="button"
            key={1}
            className={selectedButton === 1 ? "buttonSelected" : ""}
            onClick={() => {
              navigateToSection("produtos");
              setSelectedButton(1);
            }}
          >
            <p>produtos</p>
            <span />
          </button>
        </nav>
        <footer>
          <button
            type="button"
            onClick={() => {
              navigateToSection("produtos");
            }}
          >
            <img src={IconFavorite} alt="Logo Store-K" className="home__logo" />
          </button>
          <button type="button" onClick={onHandleDialog}>
            <img src={IconExit} alt="Logo Store-K" className="home__logo" />
          </button>
        </footer>
      </ContainerDesktop>
      <ContainerMobile>
        <CloseDialog
          isOpen={isOpenDialog}
          onAction={onExit}
          onCancel={onHandleDialog}
        />
        <div
          className={
            isActiveRightMenu === 0 ? "box_header" : "box_header_active"
          }
        >
          {isActiveRightMenu === 1 ? <LogoHeaderDark /> : <LogoHeader />}

          <button
            key={1}
            className={
              isActiveRightMenu === 1
                ? "button_header_open_disable"
                : "button_header_open_active"
            }
            onClick={() => {
              setIsActiveRightMenu(1);
            }}
          >
            <IconHeaderMobile />
          </button>
          <button
            key={0}
            className={
              isActiveRightMenu === 0
                ? "button_header_close_disable"
                : "button_header_close_active"
            }
            onClick={() => {
              setIsActiveRightMenu(0);
            }}
          >
            <CloseIcon fill={colors.secondary} />
          </button>
        </div>
        <div
          className={
            isActiveRightMenu === 1
              ? "box_header_info"
              : "box_header_info_close"
          }
        >
          <div
            className={
              isActiveRightMenu === 1
                ? "box_img_review"
                : "box_img_review_close"
            }
          >
            <img className="img_review" src={ImgExemple} />
            <img className="img_review_shadow" src={ImgExempleShadow} />
          </div>
          <div
            className={
              isActiveRightMenu === 1 ? "box_links" : "box_links_close"
            }
          >
            <div>
              <span>
                <h1>{name}</h1>
              </span>
              <img src={Exemple} />
            </div>
            <ul>
              <li>
                <p
                  className={
                    isActiveLink === 0 ? "text_link_select" : "text_link"
                  }
                >
                  Início
                </p>
                <button
                  key={0}
                  className={
                    isActiveLink === 0 ? "button_link_select" : "button_link"
                  }
                  onClick={() => {
                    onActionLink({
                      positionLink: 0,
                      to: "inicio"
                    });
                  }}
                >
                  <Home
                    fill={
                      isActiveLink === 0
                        ? `${colors.primary}`
                        : `${colors.secondary}`
                    }
                  />
                </button>
              </li>
              <li>
                <p
                  className={
                    isActiveLink === 1 ? "text_link_select" : "text_link"
                  }
                >
                  Produtos
                </p>

                <button
                  key={1}
                  className={
                    isActiveLink === 1 ? "button_link_select" : "button_link"
                  }
                  onClick={() => {
                    onActionLink({
                      positionLink: 1,
                      to: "produtos"
                    });
                  }}
                >
                  <Product
                    fill={
                      isActiveLink === 1
                        ? `${colors.primary}`
                        : `${colors.secondary}`
                    }
                  />
                </button>
              </li>
              <li>
                <p
                  className={
                    isActiveLink === 2 ? "text_link_select" : "text_link"
                  }
                >
                  Lista de desejos
                </p>

                <button
                  key={2}
                  className={
                    isActiveLink === 2 ? "button_link_select" : "button_link"
                  }
                  onClick={() => {
                    onActionLink({
                      positionLink: 2,
                      to: "produtos"
                    });
                  }}
                >
                  <IconFavoriteBtn
                    fill={
                      isActiveLink === 2
                        ? `${colors.primary}`
                        : `${colors.secondary}`
                    }
                  />
                </button>
              </li>
            </ul>
            <footer>
              <button onClick={onHandleDialog}>
                <p>Sair da conta</p>
                <IconExitMobile fill={colors.secondary} />
              </button>
            </footer>
          </div>
        </div>
      </ContainerMobile>
    </>
  );
}
