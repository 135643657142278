import { type DefaultIconProps } from "_types/Icons";

export function GoogleIcon({ className }: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.14 9.20419C18.14 8.56601 18.0827 7.95237 17.9764 7.36328H9.5V10.8446H14.3436C14.135 11.9696 13.5009 12.9228 12.5477 13.561V15.8192H15.4564C17.1582 14.2524 18.14 11.9451 18.14 9.20419Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49976 18.0009C11.9298 18.0009 13.967 17.195 15.4561 15.8205L12.5475 13.5623C11.7416 14.1023 10.7107 14.4214 9.49976 14.4214C7.15567 14.4214 5.17158 12.8382 4.46385 10.7109H1.45703V13.0428C2.93794 15.9841 5.98158 18.0009 9.49976 18.0009Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46409 10.7088C4.28409 10.1688 4.18182 9.59203 4.18182 8.99885C4.18182 8.40567 4.28409 7.82885 4.46409 7.28885V4.95703H1.45727C0.847727 6.17203 0.5 7.54658 0.5 8.99885C0.5 10.4511 0.847727 11.8257 1.45727 13.0407L4.46409 10.7088Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49976 3.57955C10.8211 3.57955 12.0075 4.03364 12.9402 4.92545L15.5216 2.34409C13.9629 0.891818 11.9257 0 9.49976 0C5.98158 0 2.93794 2.01682 1.45703 4.95818L4.46385 7.29C5.17158 5.16273 7.15567 3.57955 9.49976 3.57955Z"
        fill="#EA4335"
      />
    </svg>
  );
}
