import styled from "styled-components";
import { LayoutGrid } from "styled/shared";
import { breakPoints, colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";

export const Container = styled.main`
  min-height: 100vh;
  width: 100%;

  .home__area {
    position: inherit;
    border-radius: 100px 0px 0px 0px;
    max-width: 90rem !important;
    width: auto;

    ${LayoutGrid};
    .home__aside {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      & .section_top {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: end;
      }
      & .section_bottom {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        .home__scroll {
          display: flex;
          align-items: center;
          column-gap: 10px;
          padding: 7px 7px 7px 0;
          .scroll__text {
            font-size: ${pxToRem(14)};
            text-transform: uppercase;
            line-height: ${pxToRem(18.75)};
            color: ${colors.primary};
            letter-spacing: -0.02em;
            font-weight: 500;
          }
        }
      }
      & h2 {
        color: ${colors.white};
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        font-size: ${pxToRem(64)};
      }
      & h3 {
        color: ${colors.white};
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        margin-bottom: 23px;
        font-size: ${pxToRem(28)};
      }
      .home__title {
        color: ${colors.white};
        font-weight: 700;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        font-size: ${pxToRem(64)};
      }
      .home__description {
        margin-top: 24px;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(23.44)};
        font-weight: 300;
        color: ${colors.tertiary};
        letter-spacing: -0.02em;
        width: 500px;
      }
      .home__button {
        display: flex;
        align-items: center;
        background-color: ${colors.white};
        border-radius: 8px;
        cursor: pointer;
        min-height: 56px;
        transition: all 0.2s;
        width: fit-content;
        :hover {
          transform: translateY(-2px);
        }
        .button__icon {
          width: 65px;
        }
        .button__text {
          display: flex;
          align-items: center;
          background-color: ${colors.blue};
          height: 100%;
          color: ${colors.white};
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          font-size: ${pxToRem(18)};
          font-weight: 500;
          padding-inline: 22px;
        }
      }
    }
  }

  @media screen and (max-width: ${breakPoints.small}) {
    .home__background {
      ::before {
        width: 983.52px;
        height: 846.36px;
        left: -70px;
        top: -200px;
      }
    }
    .home__area .home__aside {
      align-items: center;
      .home__logo {
        display: none;
      }
      .home__title {
        font-size: ${pxToRem(64)};
        line-height: ${pxToRem(46.88)};
      }
      .home__button {
        margin-top: 48px;
      }
      .home__scroll {
        margin: 64px 0 32px;
      }
    }
  }

  @media screen and (max-width: ${breakPoints.tablet}) {
    .home__area {
      .home__aside {
        & .section_top {
          justify-content: center !important;
          h3 {
            margin-top: -100px !important;
          }
        }
        & .section_bottom {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: ${breakPoints.full}) {
    .home__area {
      .home__aside {
        align-items: start !important;
        & .section_top {
        }
        & .section_bottom {
          flex: 0.7 !important;
        }
        & h2 {
          font-size: ${pxToRem(50)} !important;
        }
        & h3 {
          font-size: ${pxToRem(30)} !important;
        }
        .home__title {
          font-size: ${pxToRem(50)} !important;
        }
        .home__description {
          font-size: ${pxToRem(17)} !important;
          width: 100% !important;
        }
      }
    }
    .home__area .home__aside {
      grid-column: 1 / span 5 !important;
      align-items: center;

      margin-left: 10%;
    }
  }

  @media screen and (max-width: ${breakPoints.average}) {
    .home__area {
      .home__aside {
        & .section_top {
        }
        & .section_bottom {
          flex: 0.7 !important;
        }
        & h2 {
          font-size: ${pxToRem(45)} !important;
        }
        & h3 {
          font-size: ${pxToRem(25)} !important;
        }
        .home__title {
          font-size: ${pxToRem(40)} !important;
        }
        .home__description {
          font-size: ${pxToRem(16)} !important;
          width: 100% !important;
        }
      }
    }
    .home__area .home__aside {
      grid-column: 1 / span 4 !important;
      align-items: center;
      margin-left: 10%;
    }
  }

  @media screen and (min-width: ${breakPoints.mobile}) {
    .home__background {
      grid-column: 7 / span 1;
      ::before {
        width: 1303px;
        height: 1121.29px;
      }
    }
    .home__area .home__aside {
      grid-column: 1 / span 6;
      margin-left: 10%;
      height: 100vh;
      .home__logo {
        width: 122px;
        height: 57px;
        object-fit: contain;
      }
      .home__title {
        margin-top: 10px;

        font-size: ${pxToRem(64)};
        line-height: ${pxToRem(56.25)};
      }
      .home__button {
        margin-top: 53px;
      }
      .home__scroll {
        margin-top: 100px;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.mobile}) {
    .home__area {
      grid-template-columns: none;
      margin-inline: auto;
      padding-inline: 0px !important;
      .home__aside {
        grid-column: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px !important;
        & .section_top {
          justify-content: center !important;
          padding-left: 30px;
          position: relative !important;
          width: 100%;
          flex: none !important;
          margin-top: 60px;
          height: calc(30vh - 60px) !important;
          overflow: hidden;
          h3 {
            margin-top: 30px !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            margin-bottom: 5px;
          }
          h2 {
            font-size: 32px !important;
          }

          .home__title {
            font-size: 32px !important;
          }
          .home__description {
            font-size: 16px !important;
            width: 80% !important;
            margin-top: 0px !important;
          }
        }
        & .section_bottom {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: ${breakPoints.smallMobile}) {
    .home__area {
      .home__aside {
        & .section_top {
          h3 {
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 22px !important;
            margin-top: 0px !important;
          }
          h2 {
            font-size: 28px !important;
            line-height: 33px !important;
          }

          .home__title {
            font-size: 28px !important;
            line-height: 30px !important;
            margin-bottom: 5px !important;
          }
          .home__description {
            font-size: 14px !important;
            line-height: 15px !important;
          }
        }
      }
    }
  }
`;
