import woman from "assets/images/woman.svg";
import { Link } from "react-router-dom";
import { ContainerError, ContainerText, Text } from "./style";
export function Error(): JSX.Element {
  return (
    <ContainerError>
      <ContainerText>
        <h3 className="text_error">ERROR 404</h3>
        <Text>
          <h1 className="text_info">
            <span className="text_1">Ops, </span>
            <span className="text_2">parece que não tem nada aqui :(</span>
          </h1>
        </Text>
        <Link to={"#"} className="return_home">
          <span className="return_text">Voltar para a home</span>
        </Link>
      </ContainerText>
      <img className="img_woman" src={woman} />
    </ContainerError>
  );
}
