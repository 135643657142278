import { DoneIcon } from "assets/icons";
import { Button } from "components/ButtonCard";
import { colors } from "styled/theme";
import { type AlertProps } from "_types/Alert";
import { ContainerAlert } from "./style";

const icon = {
  success: <DoneIcon fill={colors.light} className="box__icon" />,
  error: <DoneIcon fill={colors.danger} className="box__icon" />,
  close: <DoneIcon fill={colors.danger} className="box__icon" />
};

export function Alert({
  title,
  isOpen,
  description,
  titleAction,
  type = "normal",
  onAction,
  onCancel
}: AlertProps): JSX.Element {
  const shouldRenderIcon = type !== "normal";
  const shouldButtonCancel = typeof onCancel !== "undefined";
  return (
    <ContainerAlert isOpen={isOpen}>
      <div className="alert__background">
        <div className="alert__box">
          {shouldRenderIcon && icon[type]}
          <div className="box__infos">
            <h2 className="box__title">{title}</h2>
            <p className="box__description">{description}</p>
            <div className="box__buttons">
              {shouldButtonCancel && (
                <Button
                  label="Fechar"
                  variant="danger"
                  className="button__item"
                  onClick={onCancel}
                />
              )}
              <Button
                label={titleAction ?? "Ok"}
                className="button__item"
                onClick={onAction}
              />
            </div>
          </div>
        </div>
      </div>
    </ContainerAlert>
  );
}
