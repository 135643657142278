import { ExitIcon } from "assets/icons";
import { Button } from "components/ButtonCard";
import { type CloseDialogProps } from "_types/CloseDialog";
import { ContainerAlert } from "./style";

export function CloseDialog({
  isOpen,
  onAction,
  onCancel
}: CloseDialogProps): JSX.Element {
  return (
    <ContainerAlert isOpen={isOpen}>
      <div className="alert__background">
        <div className="alert__box">
          <ExitIcon className="box__icon" />
          <div className="box__infos">
            <h2 className="box__title">Deseja sair da sua conta?</h2>
            <p className="box__description">
              Deseja sair da sua conta? Lembre-se de que você precisará logar
              novamente na próxima visita.
            </p>
            <div className="box__buttons">
              <Button
                label="Cancelar"
                className="button__item"
                onClick={onCancel}
              />
              <Button
                label="Sair"
                className="button__item"
                onClick={onAction}
                variant="danger"
              />
            </div>
          </div>
        </div>
      </div>
    </ContainerAlert>
  );
}
