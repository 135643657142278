import styled from "styled-components";
import { breakPoints, colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";

export const Box = styled.form`
  color: ${colors.white};
  &.boxstyle {
    padding: 2rem;
    max-width: 500px;
    box-sizing: border-box;
    background: ${colors.secondary};
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
  }

  .title {
    font-size: ${pxToRem(19)};
    font-weight: 1;
    color: ${colors.white};
  }
  .question {
    font-size: ${pxToRem(15)};
    font-weight: 1;
    padding: 1.4rem 0 1rem 0;
    color: ${colors.light};
  }
  .questionUpload {
    font-size: ${pxToRem(15)};
    font-weight: 1;
    color: ${colors.light};
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    .questionWeb {
      font-size: ${pxToRem(15)};
      font-weight: 1;
      color: ${colors.tertiary};
      float: left;
      padding: 1.4rem 0 1rem 0;
      width: 8rem;
    }
    .sideQuestionWeb {
      display: flex;
      flex-direction: row;
      width: 20rem;
    }
    .questionSide {
      margin-left: 8rem;
    }
  }
  @media screen and (max-width: ${breakPoints.small}) {
    .questionWeb {
      display: none;
    }
  }
`;
export const Upload = styled.div`
  width: 100%;
  background-color: ${colors.secondGreen};
  color: ${colors.light};
  display: grid;
  border-radius: 8px;
  gap: 10px;
  @media screen and (min-width: ${breakPoints.minLarge}) {
    width: 20rem;
    margin-left: 8rem;
  }
  .upload__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed ${colors.primary};
    border-radius: inherit;
    img {
      margin: 1.5rem;
    }
  }
  .upload__button {
    display: block;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    text-decoration: underline;
    color: ${colors.primary};
    font-size: ${pxToRem(10)};
  }
  #upload__input {
    position: absolute;
    inset: 0;
    opacity: 0;
    visibility: hidden;
  }
  @media screen and (max-width: ${breakPoints.small}) {
    .questionUpload {
      display: none;
    }
    .upload__button {
      font-size: ${pxToRem(15)};
    }
  }
`;

export const Inputmodal = styled.input`
  resize: none;
  border: 1px solid ${colors.primary};
  width: 100%;
  height: 4rem;
  background-color: ${colors.secondGreen};
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 0.6rem;
  color: ${colors.light};
  ::placeholder {
    color: ${colors.light};
    font-weight: 1;
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    width: 20rem;
    margin-left: 8rem;
  }
`;
export const Counter = styled.div`
  width: 2.5rem;
  height: 1.5rem;
  text-align: center;
  background-color: ${colors.primary};
  border-radius: 4px;
  color: ${colors.dark};
  padding: 0.3rem 0rem 0.3rem 0rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Side = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonEnviar = styled.button`
  padding: 1rem;
  background: ${colors.primary};
  border-radius: 3rem;
  font-size: ${pxToRem(15)};
  text-align: center;
  color: ${colors.dark};
  margin-top: 1.5rem;
  cursor: pointer;
  :required {
    background: ${colors.darkGreen};
  }
  :disabled {
    cursor: auto;
    opacity: 0.5;
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    float: right;
  }
  @media screen and (max-width: ${breakPoints.small}) {
    width: 100%;
  }
`;
export const Buttoncancel = styled.button`
  width: 20px;
  background: none;
  margin: 0.5rem;
`;
