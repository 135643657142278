import Logo from "assets/images/logo-gok.webp";
import { GoogleIcon, QuestionMarkIcon } from "assets/icons";
import { Link } from "react-router-dom";
import { ContainerLogin } from "./style";
import { Shirts } from "./partials";
import { useLogin } from "./useLogin";
import { Alert } from "components";

export function Login(): JSX.Element {
  const { modalInfos, onLoginGoogle, onCloseAlert } = useLogin();
  return (
    <ContainerLogin>
      <Alert
        isOpen={modalInfos.isOpenAlert}
        title={modalInfos.title}
        description={modalInfos.description}
        onAction={onCloseAlert}
      />
      <div className="login__area">
        <div className="login__background1" />
        <div className="login__background2" />
        <Shirts />
        <aside className="login__aside">
          <img src={Logo} alt="Logo GO-K" className="login__logo" />
          <h1 className="login__title">Olá, Goker!</h1>
          <h2 className="login__description">
            Faça login com a sua conta GOK.
          </h2>
          <button onClick={onLoginGoogle} className="login__button">
            <GoogleIcon className="button__icon" />
            <span className="button__text">Fazer login com o Google</span>
          </button>

          {/* <Link to={"#"} className="login__help">
            <QuestionMarkIcon />
            <span className="help__text">PRECISA DE AJUDA?</span>
  </Link> */}
        </aside>
      </div>
    </ContainerLogin>
  );
}
