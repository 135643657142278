import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "config/store";
import type { sliceAwaitRequestProductSlice } from "_types/store/AwaitRequestProduct";

const initialState: sliceAwaitRequestProductSlice = {
  isAwaitRequestProduct: false
};

const awaitRequestProductSlice = createSlice({
  name: "requestProduct",
  initialState,
  reducers: {
    onHandleAwaitRequestProduct(
      _,
      { payload }: PayloadAction<sliceAwaitRequestProductSlice>
    ) {
      return payload;
    },
    onResetAwaitRequestProduct() {
      return { ...initialState };
    }
  }
});

export const { onHandleAwaitRequestProduct, onResetAwaitRequestProduct } =
  awaitRequestProductSlice.actions;

export const awaitRequestProduct = awaitRequestProductSlice.reducer;

export const selectAwaitRequestProduct = (
  state: RootState
): sliceAwaitRequestProductSlice => state.awaitRequestProduct;
