import React from "react";
import { type TitleSectionProps } from "_types/Top10";
import { ContainerTitleSection } from "./style";

export function TitleSection({
  type,
  onChangeTab
}: TitleSectionProps): JSX.Element {
  const isDisabledBtnNew = type === "new";
  const isDisabledBtnHot = type === "hot";
  return (
    <ContainerTitleSection type={type}>
      <h2 className="section__title">
        <strong>Top 10 </strong>
        {type === "new" ? " lançamentos" : "mais queridos"}
      </h2>
      <p className="section__description">
        {type === "new" ? " Produtos mais novos" : "Produtos mais favoritados"}
      </p>
      <div className="section__switch">
        <button
          type="button"
          className="switch__item switch__item--new"
          disabled={isDisabledBtnNew}
          onClick={() => {
            onChangeTab("new");
          }}
        >
          Novidades
        </button>
        <button
          type="button"
          className="switch__item switch__item--hot"
          disabled={isDisabledBtnHot}
          onClick={() => {
            onChangeTab("hot");
          }}
        >
          Mais desejados
        </button>
        <span className="switch__contrast" />
      </div>
    </ContainerTitleSection>
  );
}
