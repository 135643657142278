import { ScrollIcon } from "assets/icons";
import * as S from "./style";
import { Shirts } from "./partials/index";

export function PresentationSession(): JSX.Element {
  return (
    <S.Container id="inicio">
      <div className="home__area">
        <div className="home__background" />
        <aside className="home__aside">
          <section className="section_top">
            <h3>loja virtual</h3>

            <h2>feita para</h2>
            <h1 className="home__title">todos os estilos</h1>
            <p className="home__description">
              Descubra a StoreK: A loja Geek e Tech da GO.K, para Gokers
              modernos e antenados que gostam de andar com estilo e
              personalidade.
            </p>
          </section>
          <section className="section_bottom">
            <div className="home__scroll">
              <ScrollIcon />
              <span className="scroll__text">role para ver as novidades</span>
            </div>
          </section>
        </aside>

        <Shirts />
      </div>
    </S.Container>
  );
}
