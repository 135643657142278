import { Routes } from "config/routes";
import { ThemeProvider } from "styled-components";
import { theme } from "styled/theme";
import { Provider } from "react-redux";
import store from "config/store";
import GlobalStyle from "styled/global";

export function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
}
