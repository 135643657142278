import {
  signInWithPopup,
  GoogleAuthProvider,
  getAuth,
  type UserCredential
} from "firebase/auth";
import { firebaseApp } from "./firebaseConfig";

export async function loginAuthGoogle(): Promise<UserCredential> {
  const auth = getAuth(firebaseApp);
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  return result;
}
