import { pxToRem } from "utils/pxToRem";

export const colors = {
  white: "#FFFFFF",
  primary: "#38D430",
  lightGreen: "#7DED77",
  lemonGreen: "#3ED206",
  darkGreen: "#266322",
  secondGreen: "#1C201B",
  secondary: "#181818",
  linearBackground: "linear-gradient(287.15deg, #0C0C0C 0%, #181818 100%)",
  tertiary: "#787878",
  light: "#C6C6C6",
  dark: "#171717",
  blue: "#4285F4",
  homeBackground: "#0C0C0C",
  danger: "#FF2E54",
  orange: "#DE8533"
};

export const theme = {
  colors
};

export const breakPoints = {
  small: "600px",
  minLarge: "601px",
  full: "1400px",
  average: "1250px",
  tablet: "1020px",
  mobile: "850px",
  smallMobile: "700px"
};

export const safeArea = pxToRem(1232); // 1200px + 16px + 16px
