import React from "react";
import { type TopProps } from "_types/Showcase";
import { ContainerTop } from "./style";

export function Top({
  isDisabledButtonShowcase,
  isDisabledButtonList,
  currenTab,
  onChangeTab
}: TopProps): JSX.Element {
  const isShowcase = currenTab === "showcase";
  return (
    <ContainerTop currenTab={currenTab} id="produtos">
      <div className="header__text">
        <h2 className="header__title">
          {isShowcase ? (
            <React.Fragment>
              Vitrine <strong>GO.K</strong>
            </React.Fragment>
          ) : (
            <React.Fragment>
              Lista de <strong>Desejos</strong>
            </React.Fragment>
          )}
        </h2>
        <p className="header__description">
          {isShowcase ? (
            <React.Fragment>
              Confira todos os nossos produtos queridinhos pelos Gokers.
            </React.Fragment>
          ) : (
            <React.Fragment>
              Confira todos os seus produtos favoritados na sua lista de desejos
            </React.Fragment>
          )}
        </p>
      </div>
      <div className="showcase__switch">
        <button
          className="switch__button switch__button--left"
          disabled={isDisabledButtonShowcase}
          onClick={() => {
            onChangeTab("showcase");
          }}
        >
          vitrine de produtos
        </button>
        <button
          className="switch__button switch__button--right"
          disabled={isDisabledButtonList}
          onClick={() => {
            onChangeTab("list");
          }}
        >
          Lista de desejos
        </button>
        <span className="switch__select"></span>
      </div>
    </ContainerTop>
  );
}
