import styled from "styled-components";
import { colors } from "styled/theme";
import FooterBackground from "assets/images/footerBackground.svg";
import { pxToRem } from "utils/pxToRem";

export const Container = styled.div`
  background-image: url(${FooterBackground});
  background-size: cover;
  background-position: center;

  display: grid;
  min-height: 94vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr;
  grid-template-areas:
    "content images"
    "footer  images ";
  align-items: center;
  padding-left: ${pxToRem(90)};
  margin-top: ${pxToRem(90)};

  @media screen and (max-width: 950px) {
    padding-left: ${pxToRem(40)};
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: ${pxToRem(65)} 0 0 ${pxToRem(20)};
    gap: ${pxToRem(20)};
    align-items: flex-start;
    justify-content: space-evenly;
    padding-bottom: ${pxToRem(20)};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  grid-area: content;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: ${pxToRem(46)};
    font-weight: 700;
    letter-spacing: 0.3px;
    margin-bottom: ${pxToRem(20)};
  }

  h1:first-line {
    font-weight: 300;
  }

  h4 {
    font-size: ${pxToRem(18)};
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.3px;
    margin-bottom: ${pxToRem(40)};
  }

  button {
    width: fit-content;
    color: black;
    border: 1px solid #181818;
    padding: 1rem 2rem;
    margin-bottom: ${pxToRem(20)};
  }

  @media screen and (max-width: 767px) {
    width: 90%;
    h1 {
      font-size: ${pxToRem(35)};
    }
  }
`;

export const ImageContainer = styled.div`
  grid-area: images;
  display: flex;
  justify-content: end;

  img {
    width: 110%;
  }
`;

export const LinkContainer = styled.div`
  grid-area: footer;
  font-size: ${pxToRem(14)};
  font-weight: 300;

  div {
    display: flex;
    gap: ${pxToRem(15)};
    padding-bottom: ${pxToRem(38)};

    img {
      width: ${pxToRem(114)};
      height: ${pxToRem(20)};
    }

    a {
      color: ${colors.secondary};

      :hover {
        color: ${colors.primary};
        transform: translateY(-2px);
      }
    }
  }

  @media screen and (max-width: 767px) {
    div {
      padding-bottom: ${pxToRem(10)};
      flex-direction: column;

      div {
        flex-direction: row;
      }
    }
  }
`;
