import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  main,
  div,
  section,
  aside,
  body,
  button,
  svg,
  img {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    width: fit-content;
  }

  html {
    height: -webkit-fill-available;
    scroll-behavior: smooth;
  }

  img {
    display: block;
  }

  svg{
    transition: all 0.3s;
  }
  html {
    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p,
  label,
  a,
  button,
  input,
  strong,
  li,
  textarea {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
  }
  
  h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
p {
  font-size: 1em;
}

/* Increase font sizes by 1.5x on bigger screens */
@media (min-width: 48rem) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2.25em;
  }
  p {
    font-size: 1.5em;
  }
}
`;
