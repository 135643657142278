import { type DefaultIconProps } from "_types/Icons";

export function LinkedinIcon({ className }: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        opacity="0.15"
        x="1.20703"
        y="0.75"
        width="36.3646"
        height="36.3646"
        rx="18.1823"
        stroke="#181818"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9908 25.2431H13.0781V16.5051H15.9908V25.2431Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5258 14.5634H14.5092C13.64 14.5634 13.0781 13.9158 13.0781 13.1065C13.0781 12.2801 13.6572 11.6506 14.5429 11.6506C15.4286 11.6506 15.974 12.2801 15.9908 13.1065C15.9908 13.9158 15.4286 14.5634 14.5258 14.5634Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6705 25.2429H23.8084V20.6754C23.8084 19.5282 23.3942 18.7453 22.3581 18.7453C21.5673 18.7453 21.0963 19.2731 20.8894 19.7828C20.8138 19.9655 20.7952 20.22 20.7952 20.4752V25.2431H17.9326C17.9326 25.2431 17.9703 17.5069 17.9326 16.7058H20.7952V17.9151C21.1751 17.3338 21.8554 16.5051 23.3751 16.5051C25.2586 16.5051 26.6706 17.7256 26.6706 20.3479L26.6705 25.2429Z"
        fill="#181818"
      />
    </svg>
  );
}
