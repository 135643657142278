import { apiToken, baseAPI } from "services/api";
import { SUGESTIONS } from "services/api/endpoints";
import { type postSugestionProps } from "_types/Sugestion";

export async function postSugestion({
  payload
}: postSugestionProps): Promise<[]> {
  const response = await baseAPI.post<[]>(
    SUGESTIONS,
    { data: payload },
    apiToken
  );
  return response.data;
}
