import styled, { css } from "styled-components";
import { colors, safeArea } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import { type TopShowcaseModifier } from "_types/Showcase";
const modifier = {
  tab: {
    showcase: css`
      .switch__button--right {
        font-weight: 300;
        color: ${colors.tertiary};
      }
      .switch__button--left {
        font-weight: 400;
        color: ${colors.lemonGreen};
      }
      .switch__select {
        left: 0;
      }
      @media screen and (min-width: 761px) {
        .switch__select {
          width: 175px;
        }
      }
    `,
    list: css`
      .switch__button--left {
        font-weight: 300;
        color: ${colors.tertiary};
      }
      .switch__button--right {
        font-weight: 400;
        color: ${colors.lemonGreen};
      }
      @media screen and (max-width: 760px) {
        .switch__select {
          left: 50%;
        }
      }
      @media screen and (min-width: 761px) {
        .switch__select {
          left: 215px;
          width: 155px;
        }
      }
    `
  }
};

export const ContainerTop = styled.section<TopShowcaseModifier>`
  ${({ currenTab }) => css`
    max-width: ${safeArea};
    padding-inline: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    .header__text {
      .header__title {
        color: ${colors.white};
        line-height: ${pxToRem(71)};
        letter-spacing: 0.3px;
        font-weight: 300;
      }
      .header__description {
        color: ${colors.light};
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(32)};
        letter-spacing: 0.3px;
      }
    }
    .showcase__switch {
      position: relative;
      display: flex;
      .switch__button {
        background-color: transparent;t
        transition: all 0.2s;
        padding: 10px 0 35px 0;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(21)};
        :disabled {
          cursor: auto;
        }
        cursor: pointer;
      }
      .switch__select {
        position: absolute;
        bottom: 0;
        transition: all 0.2s;
        height: 6px;
        background-color: ${colors.lemonGreen};
        border-radius: 3px;
      }
      ${modifier.tab[currenTab]}
    }
    @media screen and (max-width: 760px) {
      margin: 60px auto 0; 
      flex-direction: column;
      .header__text {
        .header__title {
          font-size: ${pxToRem(48)};
          word-break: break-all;
        }
        .header__description {
          margin-top: 8px;
          word-break: break-all;
        }
      }
      .showcase__switch {
        column-gap: 16px;
        margin-top: 40px;
        justify-content: space-between;
        width: 100%;
        .switch__button {
          width: 50%;
        }
        .switch__select {
          width: 50%;
        }
      }
    }
    @media screen and (min-width: 761px) {
      margin: 180px auto 0; 
      .header__text {
        .header__title {
          font-size: ${pxToRem(64)};
        }
        .header__description {
          margin-top: 36px;
        }
      }
      .showcase__switch {
        column-gap: 40px;
        .switch__button--left {
          width: 175px;
        }
        .switch__button--right {
          width: 155px;
        }
      }
    }
  `}
`;
