import React from "react";
import { ContainerSkeletonProductCard } from "./style";
import { Skeleton } from "components/Skeleton";
import { type SkeletonProductCardProps } from "_types/SkeletonProductCard";

export function SkeletonProductCard({
  variant = "normal"
}: SkeletonProductCardProps): JSX.Element {
  return (
    <ContainerSkeletonProductCard variant={variant}>
      <div className="product__image">
        <Skeleton className="image__item" />
      </div>
      <div className="product__data">
        <div className="product__header">
          <Skeleton width="40%" />
          <Skeleton />
        </div>
        <div className="header__description">
          <Skeleton />
        </div>
        <div className="product__color-options">
          <Skeleton width="20px" />
          <Skeleton width="20px" />
          <Skeleton width="20px" />
        </div>
        <Skeleton className="product__button" height={50} />
      </div>
    </ContainerSkeletonProductCard>
  );
}
