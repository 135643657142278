import React from "react";
import BagImage from "assets/images/bag.png";
import StoreK from "assets/images/storek.png";
import { Button } from "components/ButtonCard";
import {
  Container,
  ContentContainer,
  ImageContainer,
  LinkContainer
} from "./styles";
import { Link } from "react-router-dom";
import { SuggestionModal } from "components";
import { navigateToSection } from "utils/navigateToSection";

export function Suggestion(): JSX.Element {
  const [modal, setModal] = React.useState(false);

  function handleModal(): void {
    setModal(!modal);
  }

  return (
    <Container>
      <SuggestionModal isOpen={modal} onHandleModal={handleModal} />
      <ContentContainer>
        <h1>
          Tem alguma ideia de <br /> novos produtos pra loja?
        </h1>
        <h4>
          Envie suas sugestões de produtos para nós, assim poderemos adicioná-la
          na loja em breve! É só clicar logo abaixo:
        </h4>
        <Button label="Enviar minha sugestão +" onClick={handleModal} />
      </ContentContainer>
      <ImageContainer>
        <img src={BagImage} alt="Bolsa da gok" />
      </ImageContainer>
      <LinkContainer>
        <div>
          <img src={StoreK} alt="StoreK" />
          <div>
            <Link
              to={"#"}
              onClick={() => {
                navigateToSection("inicio");
              }}
            >
              Início
            </Link>
            <Link
              to={"#"}
              onClick={() => {
                navigateToSection("produtos");
              }}
            >
              Vitrine
            </Link>
            <Link
              to={"#"}
              onClick={() => {
                navigateToSection("produtos");
              }}
            >
              Lista de desejos
            </Link>
          </div>
        </div>
        <span>© 2023 GOK One Step Ahead. All Rights Reserved. </span>
      </LinkContainer>
    </Container>
  );
}

export default Suggestion;
