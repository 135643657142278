import React from "react";
import { Products } from "./partials/Products";
import { TitleSection } from "./partials/TitleSection";
import { ContainerTop10 } from "./style";
import { useTop } from "./useTop";

export function Top10(): JSX.Element {
  const { onChangeTab, currenTab, products } = useTop();
  return (
    <ContainerTop10>
      <TitleSection type={currenTab} onChangeTab={onChangeTab} />;
      <Products products={products} type={currenTab} />
    </ContainerTop10>
  );
}
