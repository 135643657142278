import { IconFavoriteItem } from "assets/icons";
import { colors } from "styled/theme";
import { Container } from "./style";

export function ButtonFavorite({
  isFavorite,
  label,
  onClick,
  disabled
}: {
  isFavorite: boolean;
  label: string;
  onClick: () => void;
  disabled: boolean;
}): JSX.Element {
  return (
    <Container>
      <p>{label}</p>
      <button onClick={onClick} disabled={disabled}>
        <IconFavoriteItem
          fill={isFavorite ? `${colors.primary}` : `${colors.tertiary}`}
        />
      </button>
    </Container>
  );
}
