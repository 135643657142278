import styled, { css } from "styled-components";
import { colors } from "styled/theme";
import { type SkeletonProductCardModifier } from "_types/SkeletonProductCard";

const modifier = {
  variant: {
    normal: css`
      .product__image {
        background-color: ${colors.lightGreen};
        height: 210px;
      }
      .product__data {
        margin-top: 16px;
      }
    `,
    burst: css`
      .product__image {
        height: 290px;
        ::before {
          content: "";
          position: absolute;
          background-color: ${colors.primary};
          width: 100%;
          height: 220px;
          border-radius: 16px;
          bottom: 0;
        }
      }
      .product__data {
        position: sticky;
        background-color: ${colors.secondary};
        z-index: 1;
        margin-top: -27px;
        padding: 27px 14px 16px 14px;
        border-radius: 32px 32px 16px 16px;
      }
    `
  }
};

export const ContainerSkeletonProductCard = styled.div<SkeletonProductCardModifier>`
  width: 100%;
  height: fit-content;
  ${({ variant }) => modifier.variant[variant]};
  .product__image {
    width: 100%;
    position: relative;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image__item {
      background-color: ${colors.light};
      width: 70%;
      height: 70%;
      z-index: 1;
      ::before {
        background-color: ${colors.white};
        height: 150%;
      }
    }
  }
  .product__data {
    .product__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
    }
    .header__description {
      margin-top: 18px;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .product__color-options {
      display: flex;
      column-gap: 8px;
      align-items: center;
      margin-top: 16px;
      min-height: 20px;
    }
    .product__button {
      width: 100%;
      margin-top: 16px;
      border-radius: 48px;
    }
  }
`;
