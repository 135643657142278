import { type DefaultIconProps } from "_types/Icons";

export function IconHeaderMobile({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="10.3455"
        y="0.70343"
        width="16.8756"
        height="2.79291"
        rx="1.39645"
        fill="white"
      />
      <rect
        x="0.970215"
        y="7.60355"
        width="26.2509"
        height="2.79291"
        rx="1.39645"
        fill="white"
      />
      <rect
        x="10.3455"
        y="14.5037"
        width="16.8756"
        height="2.79291"
        rx="1.39645"
        fill="white"
      />
    </svg>
  );
}
