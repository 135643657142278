import { type DefaultIconProps } from "_types/Icons";

export function Product({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.1875 6.90625V5.46875C5.1875 4.325 5.64185 3.2281 6.4506 2.41935C7.25935 1.6106 8.35625 1.15625 9.5 1.15625C10.6437 1.15625 11.7406 1.6106 12.5494 2.41935C13.3581 3.2281 13.8125 4.325 13.8125 5.46875V6.90625M1.59375 6.90625C1.40313 6.90625 1.22031 6.98198 1.08552 7.11677C0.950725 7.25156 0.875 7.43438 0.875 7.625V17.3281C0.875 18.6866 2.03219 19.8438 3.39062 19.8438H15.6094C16.9678 19.8438 18.125 18.7427 18.125 17.3843V7.625C18.125 7.43438 18.0493 7.25156 17.9145 7.11677C17.7797 6.98198 17.5969 6.90625 17.4062 6.90625H1.59375Z"
        stroke={fill}
      />
    </svg>
  );
}
