import styled from "styled-components";
import { LayoutGrid } from "styled/shared";
import { breakPoints, colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import BGImage1 from "assets/images/bg-image1.webp";
import BGImage2 from "assets/images/bg-image2.webp";

export const ContainerLogin = styled.main`
  background-color: ${colors.dark};
  min-height: 100vh;
  min-width: 100vw;
  .login__background2,
  .login__background1 {
    position: relative;
    ::before {
      content: " ";
      background-size: contain;
      background-repeat: no-repeat;
      position: fixed;
    }
  }
  .login__background1 {
    ::before {
      background-image: url(${BGImage1});
    }
  }
  .login__background2 {
    ::before {
      background-image: url(${BGImage2});
      transform: rotate(83.5deg);
    }
  }
  .login__area {
    position: inherit;

    min-height: 100vh;
    ${LayoutGrid};
    .login__aside {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      .login__title {
        color: ${colors.white};
        font-weight: 700;
        letter-spacing: -0.02em;
      }
      .login__description {
        margin-top: 24px;
        font-size: ${pxToRem(20)};
        line-height: ${pxToRem(23.44)};
        font-weight: 300;
        color: ${colors.light};
        letter-spacing: -0.02em;
      }
      .login__button {
        display: flex;
        align-items: center;
        background-color: ${colors.white};
        border-radius: 8px;
        cursor: pointer;
        min-height: 56px;
        transition: all 0.4s ease-out;
        width: fit-content;
        :hover {
          transform: translateY(-2px);
        }
        .button__icon {
          width: 65px;
        }
        .button__text {
          display: flex;
          align-items: center;
          background-color: ${colors.blue};
          height: 100%;
          color: ${colors.white};
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          font-size: ${pxToRem(18)};
          font-weight: 500;
          padding-inline: 22px;
        }
      }
      .login__help {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 7px 7px 7px 0;
        .help__text {
          font-size: ${pxToRem(16)};
          line-height: ${pxToRem(18.75)};
          color: ${colors.light};
          letter-spacing: -0.02em;
          font-weight: 500;
        }
      }
    }
  }

  @media screen and (max-width: ${breakPoints.small}) {
    .login__background1 {
      ::before {
        left: -141px;
        top: -62px;
        width: 838.8px;
        height: 247px;
      }
    }
    .login__background2 {
      ::before {
        width: 983.52px;
        height: 846.36px;
        left: -70px;
        top: -200px;
      }
    }
    .login__area .login__aside {
      align-items: center;
      .login__logo {
        display: none;
      }
      .login__title {
        font-size: ${pxToRem(40)};
        line-height: ${pxToRem(46.88)};
      }
      .login__button {
        margin-top: 48px;
      }
      .login__help {
        margin: 64px 0 32px;
      }
    }
  }

  @media screen and (min-width: ${breakPoints.minLarge}) {
    .login__background1 {
      grid-column: 2 / span 1;
      ::before {
        width: 1580.35px;
        height: 465.36px;
      }
    }
    .login__background2 {
      grid-column: 5 / span 1;
      ::before {
        width: 1303px;
        height: 1121.29px;
      }
    }
    .login__area .login__aside {
      grid-column: 1 / span 5;
      height: 100vh;
      .login__logo {
        width: 122px;
        height: 57px;
        object-fit: contain;
      }
      .login__title {
        margin-top: 30%;
        font-size: ${pxToRem(48)};
        line-height: ${pxToRem(56.25)};
      }
      .login__button {
        margin-top: 53px;
      }
      .login__help {
        margin-top: 100px;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.smallMobile}) {
    .login__area .login__aside {
      padding-bottom: 20px;
    }
  }
`;
