import { type DefaultIconProps } from "_types/Icons";

export function AddIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-element ${className ?? ""}`}
    >
      <path
        d="M8.5 3.71875V13.2812M13.2812 8.5H3.71875"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
