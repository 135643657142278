import { constants } from "utils/constants";
import { Login } from "screens/public";
import { Home } from "screens/auth";
import { Components } from "screens/Components";

export const publicPaths = [
  {
    id: 999,
    screen: Components,
    path: "/componentes"
  },
  {
    id: 1,
    screen: Login,
    path: constants.PATHS.login
  }
];

export const authPaths = [
  {
    id: 0,
    screen: Home,
    path: constants.PATHS.home
  }
];
