import { Footer, HeaderLeft, Suggestion } from "components";
import { Outlet } from "react-router-dom";
import * as S from "./style";

export function LandingPage(): JSX.Element {
  return (
    <>
      <HeaderLeft />
      <S.Container>
        <Outlet />
        <Suggestion />
        <Footer />
      </S.Container>
    </>
  );
}
