import { type DefaultIconProps } from "_types/Icons";

export function IconFavoriteItem({
  className,
  fill = "#C6C6C6"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 0C8.83438 0 7.70438 0.555 7 1.49312C6.29562 0.555 5.16563 0 3.875 0C2.84764 0.00115796 1.86269 0.409788 1.13624 1.13624C0.409788 1.86269 0.00115796 2.84764 0 3.875C0 8.25 6.48688 11.7912 6.76312 11.9375C6.83594 11.9767 6.91732 11.9972 7 11.9972C7.08268 11.9972 7.16406 11.9767 7.23687 11.9375C7.51312 11.7912 14 8.25 14 3.875C13.9988 2.84764 13.5902 1.86269 12.8638 1.13624C12.1373 0.409788 11.1524 0.00115796 10.125 0ZM7 10.925C5.85875 10.26 1 7.23062 1 3.875C1.00099 3.11281 1.30421 2.38212 1.84316 1.84316C2.38212 1.30421 3.11281 1.00099 3.875 1C5.09062 1 6.11125 1.6475 6.5375 2.6875C6.57517 2.77921 6.63925 2.85764 6.7216 2.91284C6.80396 2.96804 6.90086 2.99752 7 2.99752C7.09914 2.99752 7.19604 2.96804 7.2784 2.91284C7.36075 2.85764 7.42483 2.77921 7.4625 2.6875C7.88875 1.64562 8.90938 1 10.125 1C10.8872 1.00099 11.6179 1.30421 12.1568 1.84316C12.6958 2.38212 12.999 3.11281 13 3.875C13 7.22562 8.14 10.2594 7 10.925Z"
        fill={fill}
      />
    </svg>
  );
}
