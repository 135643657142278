import styled, { css } from "styled-components";
import { colors } from "styled/theme";
import { pxToRem } from "utils/pxToRem";
import { type CloseDialogModifier } from "_types/CloseDialog";

const modifier = {
  controller: {
    open: css`
      .alert__background {
        opacity: 1;
        .alert__box {
          transform: scale(1);
        }
      }
    `,
    close: css`
      .alert__background {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0.2s;
      }
      .alert__box {
        transform: scale(0);
      }
    `
  }
};

export const ContainerAlert = styled.div<CloseDialogModifier>`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  .alert__background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.65);
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 3;
    transition: all 0.2s;
    .alert__box {
      width: 435px;
      border-radius: 8px;
      padding: 30px 35px;
      background-color: ${colors.secondary};
      transition: all 0.2s;
      transition-delay: 0.1s;
      display: flex;
      column-gap: 30px;
      height: fit-content;
      .box__icon {
        width: 67px;
        height: 66px;
      }
      .box__infos {
        flex: 1;
        display: block;
        .box__title {
          color: ${colors.white};
          font-size: ${pxToRem(24)};
          line-height: ${pxToRem(28)};
          font-weight: 300;
        }
        .box__description {
          color: ${colors.tertiary};
          font-size: ${pxToRem(14)};
          line-height: ${pxToRem(26)};
          margin: 16px 0 35px;
          display: block;
        }
        .box__buttons {
          display: flex;
          column-gap: 9px;
          .button__item {
            flex: 1;
          }
        }
      }
    }
  }
  ${({ isOpen }) =>
    isOpen ? modifier.controller.open : modifier.controller.close}
`;
