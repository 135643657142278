import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "config/store";
import { type sliceUser } from "_types/store/User";

const initialState: sliceUser = {
  name: "",
  email: "",
  photoURL: "",
  isAuthenticated: false
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onHandleUser(_, { payload }: PayloadAction<sliceUser>) {
      return { ...payload, isAuthenticated: true };
    },
    onLogOut() {
      return { ...initialState };
    }
  }
});

export const { onHandleUser, onLogOut } = userSlice.actions;

export const user = userSlice.reducer;

export const selectUser = (state: RootState): sliceUser => state.user;
