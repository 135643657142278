import React from "react";
import { ContainerShirts } from "./style";
import { ButtonFavorite, Skeleton } from "components";
import Thumbnail from "assets/images/Thumbnail.svg";
import BGImage1 from "assets/images/imgBackground.svg";
import BgLoader from "assets/images/BgLoader.svg";

import { ScrollIcon } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  onAddFavoriteProduct,
  selectProducts
} from "config/store/slices/productSlice";
import { selectUser } from "config/store/slices/userSlice";
import { postWishlist } from "services/hooks/useWishilist";
import { selectAwaitRequestProduct } from "config/store/slices/awaitRequestProductSlice";

export function Shirts(): JSX.Element {
  const [selectedButton, setSelectedButton] = React.useState(1);
  const [isAwaitRequest, setIsAwaitRequest] = React.useState(false);
  const { email } = useSelector(selectUser);
  const { showcase } = useSelector(selectProducts);
  const { isAwaitRequestProduct } = useSelector(selectAwaitRequestProduct);
  const dispatch = useDispatch();
  const featuredColors = ["black", "black", "white"];
  const products = [
    showcase.filter(item => item.id === 11)[0],
    showcase.filter(item => item.id === 13)[0],
    showcase.filter(item => item.id === 12)[0]
  ];
  const urlBack = process.env.REACT_APP_BASE_URL_BACK ?? "";

  async function onAddFavorite({
    id,
    variant
  }: {
    id: number;
    variant: string;
  }): Promise<void> {
    try {
      setIsAwaitRequest(true);
      const payload = {
        product: id,
        user: email ?? "",
        variant
      };
      const response = await postWishlist({ payload });
      dispatch(
        onAddFavoriteProduct({
          idProduct: id,
          idFavorite: response.data.id,
          variant
        })
      );
    } catch (error) {
    } finally {
      setIsAwaitRequest(false);
    }
  }
  return (
    <ContainerShirts>
      <div className="background_img">
        <img src={BGImage1} />
      </div>
      <div className="thumbnail_img">
        <img src={Thumbnail} />
      </div>

      <div className="shirts__roll">
        {(products ?? []).map((item, index) => {
          const variantColor =
            (item?.attributes?.images?.data ?? []).findIndex(
              (item: any) =>
                item?.attributes?.alternativeText === featuredColors[index]
            ) ?? 0;
          return (
            <div key={index} className="box_presentation">
              {!isAwaitRequestProduct ? (
                <img
                  key={index}
                  src={`${urlBack}${String(
                    item?.attributes?.images.data[variantColor].attributes.url
                  )}`}
                  className={
                    selectedButton === index + 1 ? "imgSelected" : "imgDisbled"
                  }
                />
              ) : (
                <div className="skeketon">
                  <img className="imgLoader" src={BgLoader} />
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="box__test"></div>
        <div className="shirts_select_item">
          <section className="section_badge">
            <button
              key={1}
              className={selectedButton === 1 ? "buttonSelected" : ""}
              onClick={() => {
                setSelectedButton(1);
              }}
            />
            <div className="box_badge"></div>
            <button
              key={2}
              className={selectedButton === 2 ? "buttonSelected" : ""}
              onClick={() => {
                setSelectedButton(2);
              }}
            />
            <div className="box_badge"></div>
            <button
              key={3}
              className={selectedButton === 3 ? "buttonSelected" : ""}
              onClick={() => {
                setSelectedButton(3);
              }}
            />
          </section>
          <section className="section_card_item">
            {products.map((item, index) => {
              const position = index + 1;
              const isAlreadyFavorite =
                item?.favorits &&
                item.favorits.some(
                  (item: any) => item.variant === featuredColors[index]
                );
              const variantColor =
                (item?.attributes?.images?.data ?? []).findIndex(
                  (item: any) =>
                    item?.attributes?.alternativeText === featuredColors[index]
                ) ?? 0;
              return (
                <div
                  key={index}
                  className={
                    selectedButton === position
                      ? "active_card_item"
                      : "disabled_card_item"
                  }
                >
                  <div
                    onClick={() => {
                      setSelectedButton(position);
                    }}
                    className="img_card_item"
                  >
                    {!isAwaitRequestProduct ? (
                      <img
                        style={{
                          objectFit: "contain"
                        }}
                        src={`${urlBack}${String(
                          item?.attributes?.images.data[variantColor ?? 0]
                            .attributes.url
                        )}`}
                      />
                    ) : (
                      <Skeleton className="skeleton_card" />
                    )}
                  </div>
                  <div className="info_card_item">
                    {!isAwaitRequestProduct ? (
                      <p>{`${String(item?.attributes?.name).substring(
                        0,
                        10
                      )}...`}</p>
                    ) : (
                      <Skeleton width="80px" />
                    )}
                    {!isAwaitRequestProduct ? (
                      <ButtonFavorite
                        disabled={isAlreadyFavorite === true || isAwaitRequest}
                        label={
                          isAlreadyFavorite === true
                            ? "Favoritado"
                            : "Favoritar"
                        }
                        isFavorite={isAlreadyFavorite === true}
                        onClick={() => {
                          onAddFavorite({
                            id: item.id,
                            variant: featuredColors[index]
                          });
                        }}
                      />
                    ) : (
                      <Skeleton
                        width="80px"
                        className="skeleton_card_favorite"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </section>
          <section className="section_bottom">
            <div className="home__scroll">
              <ScrollIcon />
              <span className="scroll__text">role para ver as novidades</span>
            </div>
          </section>
        </div>
      </div>
    </ContainerShirts>
  );
}
