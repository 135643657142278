import styled from "styled-components";
import { breakPoints, colors } from "styled/theme";

export const Container = styled.div`
  height: 30px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    display: flex;
    padding-right: 2px;
    font-size: 16px;
    font-weight: 300 !important;
    color: ${colors.dark} !important;
  }
  button {
    cursor: pointer;
    background-color: transparent;
    margin-top: 10px;
    padding: 5px;
    transition: all 0.3s;
  }

  @media screen and (max-width: ${breakPoints.smallMobile}) {
    p {
      font-size: 14px !important;
      margin-top: -5px;
      display: none;
    }
    button {
      margin-top: 0px;
      background-color: #ffffff;
      width: 35px;
      height: 35px;
      border-radius: 35px;
      margin-bottom: -20px;
      border: solid 1px ${colors.primary};
      box-shadow: 0px 0px 2px gray;
      justify-content: center;
      align-items: center;
      display: flex;
      transition: all 0.3s;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
