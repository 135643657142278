import axios from "axios";

export const baseAPI = axios.create({
  baseURL: process.env.REACT_APP_API
});

export const apiToken = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_KEY_API ?? ""}`
  }
};
