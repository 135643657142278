import styled from "styled-components";
import { breakPoints, colors, safeArea } from "styled/theme";
import { pxToRem } from "utils/pxToRem";

export const ContainerProducts = styled.div`
  display: flex;
  padding-inline: 16px;
  column-gap: 30px;
  .products__infos {
    .infos__title {
      color: ${colors.white};
      letter-spacing: 0.3px;
      font-weight: 300;
    }
    .infos__description {
      margin-top: 24px;
      color: ${colors.light};
      letter-spacing: 0.3px;
      font-size: ${pxToRem(18)};
      line-height: ${pxToRem(32)};
    }
    .infos__button {
      margin-top: 48px;
      padding-inline: 50px;
    }
  }
  @media screen and (max-width: ${breakPoints.small}) {
    margin-top: 38px;
    position: relative;
    min-height: 490px;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 20px;

    .products__infos {
      width: 70px !important;
      height: 100%;
      .infos__title {
        font-size: ${pxToRem(50)};
        writing-mode: vertical-rl;
        transform: rotate(180deg);
      }
      .infos__description,
      .infos__button {
        display: none;
      }
    }
    .products__list {
      margin-left: -10px;
      .list__content {
        display: grid;
        grid-template-columns: repeat(3, 220px);
        column-gap: 20px;
      }
    }
  }
  @media screen and (min-width: ${breakPoints.minLarge}) {
    margin-top: 88px;
    margin-inline: auto;
    max-width: ${safeArea};
    .products__infos {
      max-width: 350px;
      min-height: 450px;
      .infos__title {
        font-size: ${pxToRem(64)};
        line-height: ${pxToRem(71)};
      }
    }
    .products__list {
      position: relative;
      .list__content {
        position: absolute;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: repeat(10, 255px);
        column-gap: 30px;
      }
    }
  }
`;
