import { type DefaultIconProps } from "_types/Icons";

export function TrashIcon({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-element ${className ?? ""}`}
    >
      <path
        d="M13.5 6.75L12.0037 15.2595C11.9427 15.607 11.7611 15.9219 11.4909 16.1488C11.2207 16.3757 10.8791 16.5 10.5262 16.5H4.47375C4.12091 16.5 3.77935 16.3757 3.50912 16.1488C3.23889 15.9219 3.05729 15.607 2.99625 15.2595L1.5 6.75M14.25 4.5H10.0312M10.0312 4.5V3C10.0312 2.60218 9.87321 2.22064 9.59191 1.93934C9.31061 1.65804 8.92907 1.5 8.53125 1.5H6.46875C6.07093 1.5 5.68939 1.65804 5.40809 1.93934C5.12679 2.22064 4.96875 2.60218 4.96875 3V4.5M10.0312 4.5H4.96875M0.75 4.5H4.96875"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
