import { type DefaultIconProps } from "_types/Icons";

export function Home({
  className,
  fill = "#FFFFFF"
}: DefaultIconProps): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 16V7.5C17 7.34475 16.9639 7.19164 16.8944 7.05279C16.825 6.91393 16.7242 6.79315 16.6 6.7L9.6 1.45C9.4269 1.32018 9.21637 1.25 9 1.25C8.78363 1.25 8.5731 1.32018 8.4 1.45L1.4 6.7C1.2758 6.79315 1.175 6.91393 1.10557 7.05279C1.03614 7.19164 1 7.34475 1 7.5V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17H16C16.2652 17 16.5196 16.8946 16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16Z"
        stroke={fill}
      />
    </svg>
  );
}
